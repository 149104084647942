import { Button, Col, Input, message, Modal, Row } from 'antd';
import { requestDeleteContents, requestEditMultipleContents } from 'api';
import ContentsTable from 'components/contents/ContentsTable';
import MultipleModal from 'components/contents/MultipleModal';
import { routePaths } from 'constants/pathMap';
import qs from 'query-string';
import * as R from 'ramda';
import { useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import useSWR from 'swr';
import { camelize } from 'utils/obj';

const DEFAULT_PAGE_SIZE = 20;
function VideoContentsPage() {
  const history = useHistory();
  const location = useLocation();

  const searchParams = qs.parse(location.search);

  const [page, setPage] = useState(searchParams?.page ? +searchParams?.page : 0);
  const [multipleModalVisible, setMultipleModalVisible] = useState(false);
  const [pageSize] = useState(DEFAULT_PAGE_SIZE);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const offset = useMemo(() => (page === 0 ? 0 : page * pageSize), [page, pageSize]);
  const { data, mutate } = useSWR(
    `/contents?category=영상&offset=${offset}&limit=${pageSize}&keyword=${searchParams?.keyword ?? ''}`
  );

  const deleteContents = ({ id }) => {
    (async () => {
      const { error } = await requestDeleteContents({ id });

      if (error) {
        Modal.error({
          title: '콘텐츠 삭제에 실패하였습니다.',
        });
        return;
      }

      message.success('콘텐츠가 삭제되었습니다.');
      mutate();
    })();
  };

  const openDeleteConfirmModal = (e, record) => {
    e.stopPropagation();
    Modal.confirm({
      title: '정말로 삭제하시겠습니까?',
      content: `${record.title} 콘텐츠가 삭제됩니다.`,
      okText: '삭제하기',
      cancelText: '취소하기',
      okButtonProps: {
        type: 'danger',
      },
      onOk: () => deleteContents({ id: record.id }),
    });
  };
  const contentsList = useMemo(() => (data?.list ? camelize(data.list) : []), [data]);

  const paginationConfig = {
    total: data?.total,
    showSizeChanger: false,
    pageSize: DEFAULT_PAGE_SIZE,
    current: page + 1,
  };

  const handleChange = (pagination, filters, sorter, extra) => {
    if (extra.action === 'paginate') {
      history.push(`${routePaths.CONTENTS_VIDEO}?page=${pagination.current - 1}`);
      setPage(pagination.current - 1);
    }
  };

  const handleSearch = (searchValue) => {
    history.push(`${routePaths.CONTENTS_VIDEO}?page=${page}&keyword=${searchValue}`);
  };

  const handleRowSelectionChange = (rowKeys) => {
    setSelectedRowKeys(rowKeys);
  };

  const openMultipleModal = () => {
    setMultipleModalVisible(true);
  };
  const closeMultipleModal = () => {
    setMultipleModalVisible(false);
  };

  const handleMultipleEditButtonClick = () => {
    openMultipleModal();
  };

  const editMultipleContents = (values) => {
    const arrangeCategories = {
      category1_id: values?.category[0] || null,
      category2_id: values?.category[1] || null,
      category3_id: values?.category[2] || null,
      category4_id: values?.category[3] || null,
    };
    (async () => {
      const { error } = await requestEditMultipleContents({
        ids: selectedRowKeys,
        ...R.omit(['category'], values),
        ...arrangeCategories,
      });
      if (error) {
        console.error(error.response);
        Modal.error({
          title: '일괄 수정에 실패하였습니다.',
        });

        return;
      }

      closeMultipleModal();
      mutate();
    })();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [searchParams?.page]);

  return (
    <div>
      <Row style={{ paddingBottom: 12 }} justify="space-between">
        <Col span={8}>
          <Input.Search onSearch={handleSearch} />
        </Col>
        <Col>
          <Button style={{ marginRight: 16 }} onClick={handleMultipleEditButtonClick}>
            일괄 수정
          </Button>
          <Link to={routePaths.CONTENTS_VIDEO_CREATE}>
            <Button type="primary">영상 추가하기</Button>
          </Link>
        </Col>
      </Row>
      <ContentsTable
        onChange={handleChange}
        dataSource={contentsList}
        openDeleteConfirmModal={openDeleteConfirmModal}
        rowKey="id"
        pagination={paginationConfig}
        onRow={(record) => {
          return {
            onClick: (e) => {
              if (e.target.classList.contains('ant-table-selection-column')) {
                e.preventDefault();
                e.stopPropagation();

                return;
              }
              history.push(`${routePaths.CONTENTS_VIDEO}/${record.id}`);
            },
          };
        }}
        rowSelection={useMemo(
          () => ({
            preserveSelectedRowKeys: true,
            onChange: handleRowSelectionChange,
          }),
          []
        )}
      />

      {multipleModalVisible && (
        <MultipleModal
          visible={multipleModalVisible}
          onCancel={closeMultipleModal}
          onMultipleEdit={editMultipleContents}
        />
      )}
    </div>
  );
}

export default VideoContentsPage;
