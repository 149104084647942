import { Col, message, Row, Modal, Tree } from 'antd';
import { requestCreateCategory, requestDeleteCategory, requestEditCategory, requestReOrderCategory } from 'api';
import TreeTitle from 'components/category/TreeTitle';
import * as R from 'ramda';
import { useCallback } from 'react';
import useSWR from 'swr';

const VIDEO_KEY = 1;
const QUIZ_KEY = 32;

const depths = {};
function addDepthToCategory(category) {
  const result = category?.children.map((o) => ({
    ...o,
    depth: (depths[o.key] = o.parent_key in depths ? depths[o.parent_key] + 1 : 1),
    children: addDepthToCategory(o),
  }));

  return result;
}

function CategoryPage() {
  const { data, mutate } = useSWR('/category');

  const checkDraggable = useCallback((node) => {
    if (node.key === VIDEO_KEY || node.key === QUIZ_KEY) {
      return false;
    }

    return true;
  }, []);

  const [category] = data?.list
    ? [
        R.pipe(
          JSON.stringify,
          R.replace(/id/g, 'key'),
          R.replace(/name/g, 'title'),
          R.replace(/child/g, 'children'),
          JSON.parse
        )(data.list),
      ]
    : [[], []];

  const [videoCategory, quizCategory] = category;

  const findNodeFromCategory = (category, id) => {
    let node;
    category.some((n) => {
      if (n.key === id) {
        return (node = n);
      }

      if (n.children.length > 0) {
        return (node = findNodeFromCategory(n.children, id));
      } else {
        return false;
      }
    });

    return node || null;
  };

  const changeOrder = ({ dragNode, dropPosition }) => {
    const node = findNodeFromCategory(category, dragNode.parent_key);
    const siblingKeys = node.children.map((n) => n.key);
    // TODO: 선택된 노드가 기준이 애매해서 아랫방향으로 내릴 때 꼬임
    const indexOfDragNode = siblingKeys.indexOf(dragNode.key);
    const [removed] = siblingKeys.splice(indexOfDragNode, 1);
    siblingKeys.splice(dropPosition, 0, removed);

    (async () => {
      const { data, error } = await requestReOrderCategory({
        parentId: node.key,
        list: siblingKeys,
      });

      if (error) {
        return error;
      }

      if (data) {
        mutate();
      }
    })();
  };

  const handleDrop = ({ event, node, dragNode, dropToGap, dropPosition, dragNodesKeys }, rootCategoryName) => {
    if (node.pos.length !== dragNode.pos.length) {
      message.error('해당 카테고리는 순서 변경을 할 수 없습니다.');
      return;
    }

    const nodeDepth = node.pos.split('-');
    const dragNodeDepth = dragNode.pos.split('-');
    const lastNodeParentDepth = nodeDepth[nodeDepth.length - 2];
    const lastDragNodeParentDepth = dragNodeDepth[dragNodeDepth.length - 2];

    if (lastNodeParentDepth === lastDragNodeParentDepth) {
      changeOrder({ dragNode, dropPosition });
    } else {
      message.error('같은 카테고리 내에서만 순서 변경이 가능합니다.');
    }
  };

  const createCategory = useCallback(
    async (values) => {
      const { error } = await requestCreateCategory(values);

      if (error) {
        console.error(error.response);
        Modal.error({
          title: error.response.status === 409 ? error.response.data.description : '카테고리 추가에 실패하였습니다.',
        });
        return;
      }

      mutate();
      return true;
    },
    [mutate]
  );

  const editCategory = useCallback(
    async (params) => {
      const { error } = await requestEditCategory(params);
      if (error) {
        console.error(error.response);
        Modal.error({
          title: error.response.status === 409 ? error.response.data.description : '카테고리 수정에 실패하였습니다.',
        });
        return;
      }

      mutate();
      return true;
    },
    [mutate]
  );

  const deleteCategory = useCallback(
    async (params) => {
      const { error } = await requestDeleteCategory(params);
      if (error) {
        Modal.error({
          title: error.response.status === 409 ? error.response.data.description : '카테고리 삭제에 실패하였습니다.',
        });
        return;
      }

      message.success(`${params.title} 카테고리를 삭제하였습니다.`);

      mutate();
      return true;
    },
    [mutate]
  );

  const renderTreeTitle = (nodeData) => {
    return (
      <TreeTitle
        nodeData={nodeData}
        createCategory={createCategory}
        editCategory={editCategory}
        deleteCategory={deleteCategory}
      />
    );
  };

  if (data?.error) {
    return <div>카테고리 목록을 불러 올 수 없습니다.</div>;
  }
  return (
    <Row gutter={16}>
      <Col span={12}>
        <h1>영상 카테고리</h1>
        <div>
          {videoCategory && (
            <Tree
              onDrop={(dropProps) => handleDrop(dropProps, 'video')}
              draggable={checkDraggable}
              treeData={[
                {
                  ...videoCategory,
                  children: addDepthToCategory(videoCategory),
                },
              ]}
              titleRender={renderTreeTitle}
              showLine={true}
              blockNode={true}
              defaultExpandAll={true}
            />
          )}
        </div>
      </Col>
      <Col span={12}>
        <h1>퀴즈 카테고리</h1>
        <div>
          {quizCategory && (
            <Tree
              onDrop={(dropProps) => handleDrop(dropProps, 'quiz')}
              draggable={checkDraggable}
              treeData={[quizCategory]}
              titleRender={renderTreeTitle}
              showLine={true}
              blockNode={true}
              defaultExpandAll={true}
            />
          )}
        </div>
      </Col>
    </Row>
  );
}

export default CategoryPage;
