import React from 'react';
import ReactDOM from 'react-dom';
import { RecoilRoot } from 'recoil';
import { SWRConfig } from 'swr';

import App from './App';
import GlobalStyle from './GlobalStyles';
import api from './api';
import './index.css';
import './summernote.css';

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyle />
    <SWRConfig
      value={{
        fetcher: (url, init) =>
          api(
            {
              method: 'get',
              url,
            },
            init
          ),
      }}
    >
      <RecoilRoot>
        <App />
      </RecoilRoot>
    </SWRConfig>
  </React.StrictMode>,
  document.getElementById('root')
);
