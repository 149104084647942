import api from 'api';
import { snakize } from 'utils/obj';

export const getEventList = async (params) => {
  const { data, error } = await api({
    method: 'get',
    url: '/event',
    data: snakize(params),
  });

  return {
    data,
    error,
    requestName: 'GET_EVENT_LIST',
  };
};

export const getEvent = async ({ id }) => {
  const { data, error } = await api({
    method: 'get',
    url: `/event/${id}`,
  });

  return {
    data,
    error,
    requestName: 'GET_EVENT',
  };
};

export const createEvent = async (params) => {
  const { data, error } = await api({
    method: 'post',
    url: '/event',
    data: snakize(params),
  });

  return {
    data,
    error,
    requestName: 'POST_EVENT',
  };
};

export const deleteEvent = async ({ id }) => {
  const { data, error } = await api({
    method: 'delete',
    url: `/event/${id}`,
  });

  return {
    data,
    error,
    requestName: 'DELETE_EVENT',
  };
};

export const patchEvent = async ({ id, ...params }) => {
  const { data, error } = await api({
    method: 'patch',
    url: `/event/${id}`,
    data: snakize(params),
  });

  return {
    data,
    error,
    requestName: 'PATCH_EVENT',
  };
};
