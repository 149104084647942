import { postComment } from 'api/requests/boardRequests';
import { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';

export default function CommentForm({
  id,
  index,
  parentId = null,
  parentIndex,
  boardId,
  onClose,
  editMode,
  className,
  defaultContent,
}) {
  const limit = 200;

  const formRef = useRef();
  const [commentLength, setCommentLength] = useState(defaultContent?.length || 0);

  const handlePostComment = useCallback(
    (content) => {
      (async () => {
        const { error } = await postComment({ boardId, parentId, content });

        console.log(error);
        if (error) {
          console.error(error.response);
          alert('댓글 등록에 실패하였습니다.');
          return;
        }

        // window.location.reload();
      })();
    },
    [boardId, parentId]
  );

  const editComment = useCallback(
    (content) => {
      (async () => {
        const { error } = {}; // await requestEditComment({ id, content });

        if (error) {
          console.error(error.response);
          alert(error.response?.status);
          return;
        }
        window.location.reload();

        onClose && onClose();
      })();
    },
    [onClose]
  );

  const handleClick = useCallback(() => {
    const content = formRef.current.value.trim() || '';

    if (editMode) {
      editComment(content);
    } else {
      if (!boardId || !content) {
        return;
      }
      handlePostComment(content, boardId);
    }

    formRef.current.value = '';
  }, [editMode, boardId, handlePostComment, editComment]);

  const handleSaveKeyPress = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        handleClick();
      }
    },
    [handleClick]
  );

  const handleCloseKeyPress = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        onClose && onClose();
      }
    },
    [onClose]
  );

  const handleCommentChange = useCallback((e) => {
    // Auto-resize height of textarea
    e.target.style.height = '1px';
    e.target.style.height = e.target.scrollHeight + 2 + 'px';

    setCommentLength(e.target.value.length);
  }, []);

  return (
    <CommentFormWrapper className={className}>
      <StyledTextArea
        ref={formRef}
        onChange={handleCommentChange}
        placeholder="댓글을 남겨주세요."
        defaultValue={defaultContent || ''}
        autoComplete="off"
        maxLength={limit}
      />
      <MaxLength>
        <span>{commentLength}</span> / {limit}
      </MaxLength>

      <ButtonsWrap>
        <SaveBtn tabIndex="0" onClick={handleClick} onKeyPress={handleSaveKeyPress}>
          {editMode ? '수정하기' : parentId ? '대댓글 등록' : '댓글 등록'}
        </SaveBtn>
        {editMode && (
          <CloseBtn tabIndex="1" onClick={onClose} onKeyPress={handleCloseKeyPress}>
            닫기
          </CloseBtn>
        )}
      </ButtonsWrap>
    </CommentFormWrapper>
  );
}

const CommentFormWrapper = styled.div`
  position: relative;
  margin-bottom: 40px;
`;

const StyledTextArea = styled.textarea`
  width: 100%;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  min-height: 115px;
  resize: none;
  padding: 15px 15px 54px;
  font-size: 1.1rem;
  color: #4a4a4a;
  line-height: 1.3;
  outline: none;
  transition: border 0.3s ease;
  background-color: #fff;
  margin-top: 10px;

  &:focus {
    border: 1px solid #d0d0d0;
  }
`;

const ButtonsWrap = styled.div`
  display: flex;
  position: absolute;
  bottom: 12px;
  right: 15px;
`;

const Btn = styled.div`
  border: none;
  color: #fff;
  font-size: 1.1rem;
  font-weight: bold;
  padding: 10px 13px;
  border-radius: 5px;
  cursor: pointer;
`;

const CloseBtn = styled(Btn)`
  color: #aaa;
  margin-right: 4px;
`;

const SaveBtn = styled(Btn)`
  background: #ffcc2b;

  ${StyledTextArea}:placeholder-shown ~ ${ButtonsWrap} & {
    background: none;
    color: #aaa;
    cursor: auto;
  }
`;

const MaxLength = styled.div`
  position: absolute;
  top: calc(100% + 10px);
  right: 10px;
  color: #999;
`;
