import * as R from 'ramda';
import useSWR from 'swr';

function useCategory(isVideoPage) {
  const { data } = useSWR('/category');
  const [videoCategory, quizCategory] = data ? data.list : [[], []];

  const idNameToValueLabelCategories = data
    ? [
        R.pipe(
          JSON.stringify,
          R.replace(/id/g, 'value'),
          R.replace(/name/g, 'label'),
          R.replace(/child/g, 'children'),
          JSON.parse
        )(isVideoPage ? videoCategory : quizCategory),
      ]
    : [];
  return idNameToValueLabelCategories;
}

export default useCategory;
