import { Popover } from 'antd';
import React from 'react';
import styled from 'styled-components';

const Styled = {
  popover: styled(Popover)`
    width: 400px;
  `,
  imageWrapper: styled.div`
    overflow: hidden;
  `,
  previewImage: styled.img`
    width: 260px;
    overflow: hidden;
    object-fit: contain;
  `,
  thumbnail: styled.img`
    width: 40px;
    height: 40px;
  `,
};

function Thumbnail({ src }) {
  const content = (
    <Styled.imageWrapper>
      <Styled.previewImage alt="프리뷰" src={src} />
    </Styled.imageWrapper>
  );
  return (
    <Styled.popover overlayClassName="thumb-popover" content={content} placement="left">
      <Styled.thumbnail alt={src} src={src} />
    </Styled.popover>
  );
}

export default Thumbnail;
