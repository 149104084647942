import { DeleteOutlined } from '@ant-design/icons';
import { Button, Table, Modal } from 'antd';

import { columns } from './RecommendForm';

function RecieveTable({ contentsList, onDeleteContents, maxContents }) {
  const openDeleteConfirmModal = (e, record) => {
    e.stopPropagation();
    Modal.confirm({
      title: '정말로 삭제하시겠습니까?',
      content: `${record.title} 콘텐츠가 삭제됩니다.`,
      okText: '삭제하기',
      cancelText: '취소하기',
      okButtonProps: {
        type: 'danger',
      },
      onOk: () => onDeleteContents({ id: record.id }),
    });
  };
  const receiveTableColumn = [
    ...columns,
    {
      title: '액션',
      dataIndex: 'id',
      render: (id, record) => {
        return (
          <Button
            type="danger"
            shape="circle"
            icon={<DeleteOutlined />}
            onClick={(e) => openDeleteConfirmModal(e, record)}
          />
        );
      },
      fixed: 'right',
    },
  ];

  return (
    <>
      <Table
        columns={receiveTableColumn}
        dataSource={contentsList.map((contents) => ({
          key: contents.id,
          ...contents,
          category: contents.category4Name || contents.category2Name,
          imageThumbnail: contents.thumbnail_url || contents.imageThumbnail,
        }))}
        rowKey="id"
        pagination={false}
        scroll={{ x: 1500 }}
      />
      <div style={{ textAlign: 'right', paddingRight: 5, marginTop: 5, fontWeight: 500 }}>
        ({contentsList.length}/{maxContents})
      </div>
    </>
  );
}

export default RecieveTable;
