import { postPost } from 'api/requests/boardRequests';
import BoardForm from 'components/board/BoardForm';
import { useHistory } from 'react-router-dom';

export default function BoardWritePage() {
  const history = useHistory();

  return (
    <>
      <BoardForm
        onSubmit={(data) => {
          if (!data.startDate) {
            delete data.startDate;
          }
          if (!data.endDate) {
            delete data.endDate;
          }

          postPost(data).then(() => {
            history.push('/board');
          });
        }}
      />
    </>
  );
}
