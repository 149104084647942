import { CommentsActions } from 'context/CommentsContextProvider';
import { useCommentsDispatch, useCommentsState } from 'hooks/useCommentsContext';
import { useEffect } from 'react';
import styled from 'styled-components';

// import CommentForm from './CommentForm';
import CommentList from './CommentList';

export default function CommentSection({ boardId, comments: _comments }) {
  const dispatch = useCommentsDispatch();
  const comments = useCommentsState();

  useEffect(() => {
    dispatch({
      type: CommentsActions.SET_COMMENTS,
      payload: {
        comments: _comments,
      },
    });
  }, [dispatch, _comments]);

  return (
    <CommentSectionWrapper>
      <CommentCount>댓글 {comments.length}</CommentCount>
      <div>
        <CommentList comments={comments} boardId={boardId} />
      </div>
      {/* <CommentForm boardId={boardId} /> */}
    </CommentSectionWrapper>
  );
}

const CommentSectionWrapper = styled.div``;
const CommentCount = styled.div`
  margin-bottom: 10px;
  padding-left: 6px;
  font-weight: bold;
`;
