import { Modal } from 'antd';
import { requestContentsCreate } from 'api';
import ContentsForm from 'components/contents/ContentsForm';
import useCategory from 'hooks/useCategory';
import * as R from 'ramda';
import { useHistory, useLocation } from 'react-router-dom';

function ContentsCreatePage() {
  const location = useLocation();
  const history = useHistory();

  const isVideoPage = location.pathname.includes('video');
  const idNameToValueLabelCategories = useCategory(isVideoPage);

  const createContents = (values) => {
    (async () => {
      const { data, error } = await requestContentsCreate(values);
      if (error) {
        console.error(error.response);
        Modal.error({
          title: error.response?.data?.description || '등록에 실패하였습니다.',
        });
        return;
      }

      if (data) {
        const url = isVideoPage ? '/contents/video' : '/contents/quiz';
        history.push(url);
      }
    })();
  };

  const handleFinish = (values) => {
    const arrangeCategories = values?.category.reduce((cur, acc, index) => {
      return { ...cur, [`category${index + 1}_id`]: acc };
    }, {});

    const reformedValues = R.pipe(
      R.omit(['category']),
      R.map((value) => {
        const hasFile = R.has('file');
        return typeof value === 'undefined' ? '' : hasFile(value) ? value.file.response.url : value;
      })
    )(values);

    createContents({
      ...reformedValues,
      ...arrangeCategories,
      ...(isVideoPage && { num_of_question: 0 }),
      ...(!isVideoPage && { running_time: 0 }),
    });
  };

  return (
    <>
      <ContentsForm
        isVideoPage={isVideoPage}
        categoryList={idNameToValueLabelCategories}
        onFinish={handleFinish}
        okText="생성하기"
        initialValues={{
          code: '',
          title: '',
          activity_time: null,
          content: '',
          url: '',
          running_time: null,
          difficulty: 0,
          image_thumbnail: [],
          sell_material: '',
          sell_material_url: '',
          self_material: '',
          access_level: null,
          reference_type: null,
          reference: '',
          guide_doc: '',
          activity_doc: '',
          design_doc: '',
          quiz_doc: '',
          category1_id: null,
          category2_id: null,
          category3_id: null,
          category4_id: null,
          num_of_question: null,
        }}
      />
    </>
  );
}
export default ContentsCreatePage;
