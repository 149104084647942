import { Table, Button, Typography } from 'antd';
import dayjs from 'dayjs';
import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import useSWR from 'swr';
import { camelize } from 'utils/obj';

const { Title } = Typography;

const PAGE_SIZE = 25;

const columns = [
  {
    title: '번호',
    dataIndex: 'no',
    key: 'no',
    width: 75,
    align: 'center',
  },
  {
    title: '제목',
    dataIndex: 'title',
    key: 'title',
    render: (text) => <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>{text}</div>,
  },
  {
    title: '글쓴이',
    dataIndex: 'account',
    key: 'account',
    width: 120,
    align: 'center',
  },
  {
    title: '작성일',
    dataIndex: 'date',
    key: 'date',
    width: 105,
    align: 'center',
  },
  {
    title: '조회수',
    dataIndex: 'viewCount',
    key: 'viewCount',
    width: 90,
    align: 'right',
  },
];

//board-notice
export default function BoardPage() {
  const history = useHistory();
  const [page, setPage] = useState(1);
  const { data } = useSWR(`/dolbom-board?offset=${(page - 1) * PAGE_SIZE}&limit=${PAGE_SIZE}`);
  const { data: noticeData } = useSWR(`/board-notice`);

  const handleTableChange = useCallback(
    (pagination, filters, sorter) => {
      if (page !== pagination.current) {
        setPage(pagination.current);
      }
    },
    [page]
  );

  if (!data || !noticeData) {
    return null;
  }

  const boardData = camelize(data.list).map((item) => ({
    key: item.id,
    no: <Wrapper isAdminDeleted={item.isAdminDeleted}>{item.id}</Wrapper>,
    title: (
      <div style={{ display: 'flex', justifyContent: 'space-between', color: item.isAdminDeleted ? '#999' : '#000' }}>
        <span>
          {item.isAdminDeleted ? '[삭제]' : ''}
          {item.title}
        </span>
        <CommentCount>{item.commentCount > 0 ? `[${item.commentCount}]` : ''}</CommentCount>
      </div>
    ),
    account: <Wrapper isAdminDeleted={item.isAdminDeleted}>{item.accountId}</Wrapper>,
    date: (
      <Wrapper isAdminDeleted={item.isAdminDeleted}>
        {dayjs().format('YYYYMMDD') === dayjs(item.createdAt).format('YYYYMMDD')
          ? dayjs(item.createdAt).format('HH:mm')
          : dayjs(item.createdAt).format('YYYY.MM.DD')}
      </Wrapper>
    ),
    viewCount: <Wrapper isAdminDeleted={item.isAdminDeleted}>{item.viewCount}</Wrapper>,
  }));

  const noticeBoardData = camelize(noticeData.list).map((item) => ({
    key: item.id,
    no: <Wrapper isAdminDeleted={item.isAdminDeleted}>{item.id}</Wrapper>,
    title: (
      <div style={{ display: 'flex', justifyContent: 'space-between', color: item.isAdminDeleted ? '#999' : '#000' }}>
        <span>
          {item.isAdminDeleted ? '[삭제]' : ''}
          {item.title}
        </span>
        <CommentCount>{item.commentCount > 0 ? `[${item.commentCount}]` : ''}</CommentCount>
      </div>
    ),
    account: <Wrapper isAdminDeleted={item.isAdminDeleted}>{item.accountId}</Wrapper>,
    date: (
      <Wrapper isAdminDeleted={item.isAdminDeleted}>
        {dayjs().format('YYYYMMDD') === dayjs(item.createdAt).format('YYYYMMDD')
          ? dayjs(item.createdAt).format('HH:mm')
          : dayjs(item.createdAt).format('YYYY.MM.DD')}
      </Wrapper>
    ),
    viewCount: <Wrapper isAdminDeleted={item.isAdminDeleted}>{item.viewCount}</Wrapper>,
  }));

  const handleRow = (record, rowIndex) => {
    return {
      onClick: (event) => {
        history.push(`/board/${record.key}`);
      },
    };
  };

  return (
    <>
      <div style={{ textAlign: 'right' }}>
        <Button
          onClick={() => {
            history.push('/board/write');
          }}
        >
          글쓰기
        </Button>
      </div>
      <Title level={2}>공지사항</Title>
      <Table
        columns={columns}
        dataSource={noticeBoardData}
        pagination={false}
        onChange={handleTableChange}
        onRow={handleRow}
      />
      <Title level={2} style={{ marginTop: 50 }}>
        게시판
      </Title>
      <Table
        columns={columns}
        dataSource={boardData}
        pagination={{
          current: page,
          pageSize: PAGE_SIZE,
          total: data.total,
        }}
        onChange={handleTableChange}
        onRow={handleRow}
      />
    </>
  );
}

const CommentCount = styled.span`
  color: rgb(153, 153, 153);
`;

const Wrapper = styled.div`
  color: ${({ isAdminDeleted }) => (isAdminDeleted ? '#999' : '#000')};
`;
