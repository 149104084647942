import { Layout, Typography } from 'antd';
import { requestCheckAuth } from 'api';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import authAtom from 'recoils/auth';
import styled from 'styled-components';

const { Header: AntdHeader } = Layout;
const { Title } = Typography;

function Header({ title }) {
  const setAuth = useSetRecoilState(authAtom);
  useEffect(() => {
    (async () => {
      const { data, error } = await requestCheckAuth();

      if (error) {
        return;
      }

      if (data) {
        setAuth(data);
      }
    })();
  }, [setAuth]);

  return (
    <FlexHeader>
      <Title level={2} style={{ color: '#fff', margin: 0 }}>
        {title}
      </Title>
    </FlexHeader>
  );
}

const FlexHeader = styled(AntdHeader)`
  display: flex;
  align-items: center;
`;

export default Header;
