import { ClockCircleOutlined, CommentOutlined, EyeOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { DeleteButton, EditButton } from './commonStyles';

export default function PostAuthor({
  author,
  createdAt,
  viewCount,
  commentCount = 0,
  isWriter,
  isAdminDeleted = false,
  onEditButtonClick,
  onDeleteButtonClick,
}) {
  return (
    <Wrapper>
      <Extra>
        <Author>{author}</Author>
        <span>
          <ClockCircleOutlined style={{ marginRight: 3, marginLeft: 10 }} />
          {dayjs().format('YYYYMMDD') === dayjs(createdAt).format('YYYYMMDD')
            ? dayjs(createdAt).format('HH:mm')
            : dayjs(createdAt).format('YYYY.MM.DD')}
        </span>
        <span>
          <EyeOutlined style={{ marginRight: 3, marginLeft: 10 }} />
          {viewCount}
        </span>
        <span>
          <CommentOutlined style={{ marginRight: 3, marginLeft: 10 }} />
          {commentCount}
        </span>
      </Extra>

      <ButtonGroup>
        {isWriter && <EditButton onClick={onEditButtonClick}>수정</EditButton>}
        <DeleteButton onClick={onDeleteButtonClick}>{isAdminDeleted ? '삭제 취소' : '삭제'}</DeleteButton>
      </ButtonGroup>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-bottom: 7px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
`;

const Author = styled.div`
  display: inline-block;
  padding: 20px 0;
  &::after {
    padding: 2px 15px 0;
    content: ' | ';
  }
`;

const Extra = styled.div`
  display: inline-flex;
  color: #999;
  span:not(.icon) {
    align-items: center;
    display: flex;
    margin-right: 12px;
  }
  svg {
    margin-right: 4px;
  }
`;

const ButtonGroup = styled.div`
  font-size: 1.15rem;
  display: flex;
`;
