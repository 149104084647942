import { postRecommend } from 'api/requests/recommendRequests';
import RecommendForm from 'components/recommend-contents/RecommendForm';
import { useHistory } from 'react-router-dom';

export default function RecommendContentsWritePage() {
  const history = useHistory();

  return (
    <RecommendForm
      onSubmit={(value) => {
        postRecommend(value).then(() => {
          history.push('/recommend-contents');
        });
      }}
    />
  );
}
