import { createEvent } from 'api/requests/eventRequests';
import EventForm from 'components/event/EventForm';
import { useHistory } from 'react-router-dom';

export default function BannerEventWritePage() {
  const history = useHistory();

  return (
    <EventForm
      onSubmit={(value) => {
        createEvent(value).then(() => {
          history.push('/banner-event');
        });
      }}
    />
  );
}
