import { UploadOutlined } from '@ant-design/icons';
import { Button, Input, Select, Upload, Form, Col, Row, DatePicker, Checkbox } from 'antd';
import { uploadRequest } from 'api';
import { EVENT_INPUTS } from 'constants/inputs';
import moment from 'moment';
import { useRef, useState } from 'react';
import styled from 'styled-components';

import DolbomSummerNote from '../board/DolbomSummerNote';

const { RangePicker } = DatePicker;

export default function EventForm({
  data = { title: '', startDate: '', endDate: '', content: '', bannerUrl: '', thumbnailUrl: '' },
  isEditMode = false,
  onSubmit,
}) {
  const [form] = Form.useForm();
  const summernoteRef = useRef(null);
  const [type, setType] = useState(data.thumbnailUrl ? 'event' : 'banner');
  const [noEndDate, setNoEndDate] = useState(data.endDate > '9999-01-01');

  const handleFinish = (fieldsValue) => {
    onSubmit &&
      onSubmit({
        title: fieldsValue.title,
        thumbnailUrl: Array.isArray(fieldsValue.thumbnailUrl)
          ? fieldsValue.thumbnailUrl?.[0]?.url || ''
          : fieldsValue.thumbnailUrl?.file?.response?.url || '',
        bannerUrl:
          (Array.isArray(fieldsValue.bannerUrl)
            ? fieldsValue?.bannerUrl[0]?.url
            : fieldsValue?.bannerUrl?.file?.response?.url) || '',
        type: fieldsValue.type === 'event' ? 'internal' : 'external',
        link: fieldsValue.link || '',
        content: summernoteRef?.current?.editor?.summernote('code') || '',
        startDate: fieldsValue.date[0]?.format('YYYY-MM-DD'),
        endDate: noEndDate ? '9999-12-01' : fieldsValue.date[1]?.format('YYYY-MM-DD'),
      });
  };

  const initialValues = {
    type: data.thumbnailUrl ? 'event' : 'banner',
    date:
      data.startDate && data.endDate ? [moment(data.startDate, 'YYYY-MM-DD'), moment(data.endDate, 'YYYY-MM-DD')] : [],
    title: data.title,
    bannerUrl: data.bannerUrl
      ? [
          {
            name: data.bannerUrl,
            url: data.bannerUrl,
            thumbUrl: data.bannerUrl,
            uid: '-1',
            status: 'done',
          },
        ]
      : [],
    link: data.link,
    thumbnailUrl: data.thumbnailUrl
      ? [
          {
            name: data.thumbnailUrl,
            url: data.thumbnailUrl,
            thumbUrl: data.thumbnailUrl,
            uid: '-1',
            status: 'done',
          },
        ]
      : [],
    content: data.content,
  };

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleFinish}
        initialValues={initialValues}
        onSubmit={(e) => {
          console.log(e);
          e.preventDefault();
        }}
      >
        <Row gutter={32}>
          <Col span={6}>
            <Form.Item label={EVENT_INPUTS.TYPE.label} name={EVENT_INPUTS.TYPE.name} rules={EVENT_INPUTS.TYPE.rules}>
              <Select
                options={EVENT_INPUTS.TYPE.options}
                placeholder={EVENT_INPUTS.TYPE.placeholder}
                onChange={(v) => {
                  setType(v);
                }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={EVENT_INPUTS.DATE.label}
              name={EVENT_INPUTS.DATE.name}
              rules={EVENT_INPUTS.DATE.rules}
              style={{ marginBottom: 10 }}
            >
              <RangePicker format={EVENT_INPUTS.DATE.format} disabled={[false, noEndDate]} />
            </Form.Item>
            <Checkbox
              checked={noEndDate}
              onChange={(e) => {
                setNoEndDate(e.target.checked);
              }}
            >
              {EVENT_INPUTS.NO_END_DATE.label}
            </Checkbox>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label={EVENT_INPUTS.TITLE.label} name={EVENT_INPUTS.TITLE.name} rules={EVENT_INPUTS.TITLE.rules}>
              <Input
                type={EVENT_INPUTS.TITLE.type}
                placeholder={EVENT_INPUTS.TITLE.placeholder}
                maxLength={EVENT_INPUTS.TITLE.maxLength}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              label={EVENT_INPUTS.BANNER_URL.label}
              name={EVENT_INPUTS.BANNER_URL.name}
              rules={EVENT_INPUTS.BANNER_URL.rules}
              valuePropName={initialValues.bannerUrl.length > 0 ? 'fileLIst' : null}
            >
              <Upload
                action={uploadRequest}
                headers={{
                  authorization: `Bearer ${(window.localStorage.getItem('__DOLBOM_ADMIN_TOKEN__') || null).replace(
                    /"/g,
                    ''
                  )}`,
                }}
                maxCount={1}
                accept="image/*"
                listType="picture"
                defaultFileList={initialValues.bannerUrl}
              >
                <Button icon={<UploadOutlined />}>{data.bannerUrl ? '수정하기' : '업로드'}</Button>
              </Upload>
            </Form.Item>
          </Col>
        </Row>
        {type === 'banner' && (
          <>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  label={EVENT_INPUTS.LINK.label}
                  name={EVENT_INPUTS.LINK.name}
                  rules={EVENT_INPUTS.LINK.rules}
                >
                  <Input type={EVENT_INPUTS.LINK.type} placeholder={EVENT_INPUTS.LINK.placeholder} />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}

        {type === 'event' && (
          <>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  label={EVENT_INPUTS.THUMBNAIL_URL.label}
                  name={EVENT_INPUTS.THUMBNAIL_URL.name}
                  rules={EVENT_INPUTS.THUMBNAIL_URL.rules}
                >
                  <Upload
                    action={uploadRequest}
                    headers={{
                      authorization: `Bearer ${(window.localStorage.getItem('__DOLBOM_ADMIN_TOKEN__') || null).replace(
                        /"/g,
                        ''
                      )}`,
                    }}
                    maxCount={1}
                    accept="image/*"
                    listType="picture"
                    defaultFileList={initialValues.thumbnailUrl}
                  >
                    <Button icon={<UploadOutlined />}>{data.thumbnailUrl ? '수정하기' : '업로드'}</Button>
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
            <DolbomSummerNote content={initialValues.content} summernoteRef={summernoteRef} />
          </>
        )}

        <AlignCenter>
          <Button htmlType="submit">{isEditMode ? '수정하기' : '등록하기'}</Button>
        </AlignCenter>
      </Form>
    </>
  );
}

const AlignCenter = styled.div`
  text-align: center;
`;
