import ReactSummernote from 'react-summernote';
import styled, { css } from 'styled-components';
import { darken } from 'polished';
import 'react-summernote/dist/react-summernote.css'; // import styles
import 'react-summernote/lang/summernote-ko-KR'; // you can import any other locale

import 'bootstrap/js/modal';
import 'bootstrap/js/dropdown';
import 'bootstrap/js/tooltip';
import './bootstrap-style.scss';
import { uploadImage } from 'api';

export default function DolbomSummerNote({ content, summernoteRef, limit = 5000 }) {
  const handlePaste = (e) => {
    const t = e.currentTarget.innerText;
    const bufferText = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('Text');
    const maxPaste = limit - t.length;

    e.preventDefault();

    document.execCommand('insertText', false, bufferText.trim().substring(0, maxPaste));
  };

  return (
    <SummernoteStyles className={'bootstrap-style'}>
      <ReactSummernote
        ref={summernoteRef}
        className="rsn-initial"
        options={{
          lang: 'ko-KR',
          height: 350,
          dialogsInBody: true,
          toolbar: [
            ['style', ['style']],
            ['font', ['bold', 'underline', 'clear']],
            ['color', ['color']],
            ['para', ['ul', 'ol', 'paragraph']],
            ['insert', ['link', 'picture']],
          ],
        }}
        onChange={(e) => {
          const t = document.querySelector('.note-editable').innerText;
          document.querySelector('#currentLength').innerText = t.length || 0;
        }}
        onKeyDown={(e) => {
          var t = e.currentTarget.innerText;

          if (t.length >= limit) {
            //delete keys, arrow keys, copy, cut, select all
            if (
              e.keyCode !== 8 &&
              !(e.keyCode >= 37 && e.keyCode <= 40) &&
              e.keyCode !== 46 &&
              !(e.keyCode === 88 && e.ctrlKey) &&
              !(e.keyCode === 67 && e.ctrlKey) &&
              !(e.keyCode === 65 && e.ctrlKey)
            ) {
              e.preventDefault();
            }
          }
        }}
        onPaste={handlePaste}

        onImageUpload={(fileList) => {
          const reader = new FileReader();
          reader.readAsDataURL(fileList[0]);

          uploadImage({image: fileList[0]}).then(({data}) =>{
            ReactSummernote.insertImage(data.url);
          });
        }}
      >
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </ReactSummernote>
      <MaxLength>
        <span id="currentLength">0</span> / {limit}
      </MaxLength>
    </SummernoteStyles>
  );
}

const MaxLength = styled.div`
  position: absolute;
  top: calc(100% + 10px);
  right: 10px;
  color: #999;
`;

const bootstrapPrimary = darken(0.065, '#428bca');

const panelVariant = (border, headingTextColor, headingBgColor, headingBorder) => css`
  border-color: ${border};

  & > .panel-heading {
    color: ${headingTextColor};
    background-color: ${headingBgColor};
    border-color: ${headingBorder};

    + .panel-collapse > .panel-body {
      border-top-color: ${border};
    }
    .badge {
      color: ${headingBgColor};
      background-color: ${headingTextColor};
    }
  }
  & > .panel-footer {
    + .panel-collapse > .panel-body {
      border-bottom-color: ${border};
    }
  }
`;

const buttonSize = (paddingVertical, paddingHorizontal, fontSize, lineHeight, borderRadius) => css`
  padding: ${paddingVertical} ${paddingHorizontal};
  font-size: ${fontSize};
  line-height: ${lineHeight};
  border-radius: ${borderRadius};
`;

const buttonVariant = (color, background, border) => css`
  color: ${color};
  background-color: ${background};
  border-color: ${border};

  &:focus,
  &.focus {
    color: ${color};
    background-color: ${darken(0.1, background)};
    border-color: ${darken(0.25, border)};
  }
  &:hover {
    color: ${color};
    background-color: ${darken(0.1, background)};
    border-color: ${darken(0.12, border)};
  }
  &:active,
  &.active,
  .open > .dropdown-toggle& {
    color: ${color};
    background-color: ${darken(0.1, background)};
    background-image: none;
    border-color: ${darken(0.12, border)};

    &:hover,
    &:focus,
    &.focus {
      color: ${color};
      background-color: ${darken(0.17, background)};
      border-color: ${darken(0.25, border)};
    }
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus,
    &.focus {
      background-color: ${background};
      border-color: ${border};
    }
  }

  .badge {
    color: ${background};
    background-color: ${color};
  }
`;

const SummernoteStyles = styled.div`
  position: relative;

  .caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-right: 4px transparent;
    border-left: 4px solid transparent;
  }

  // The dropdown wrapper (div)
  .dropup,
  .dropdown {
    position: relative;
  }

  // Prevent the focus on the dropdown toggle when closing dropdowns
  .dropdown-toggle:focus {
    outline: 0;
  }

  // The dropdown menu (ul)
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none; // none by default, but block on "open" of the menu
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0; // override default ul
    font-size: 14px;
    text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ccc; // IE8 fallback
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);

    // Aligns the dropdown menu to right
    //
    // Deprecated as of 3.1.0 in favor of '.dropdown-menu-[dir]'

    &.pull-right {
      right: 0;
      left: auto;
    }
    .divider {
      height: 1px;
      overflow: hidden;
      background-color: #e5e5e5;
    }

    // Links within the dropdown menu
    > li > a {
      display: block;
      padding: 3px 20px;
      clear: both;
      font-weight: 400;
      line-height: 1.428571429;
      color: #333;
      white-space: nowrap; // prevent links from randomly breaking onto new lines

      &:hover,
      &:focus {
        color: #333;
        text-decoration: none;
        background-color: #f5f5f5;
      }
    }
  }

  // Active state
  .dropdown-menu > .active > a {
    &,
    &:hover,
    &:focus {
      color: #fff;
      text-decoration: none;
      background-color: #337ab7;
      outline: 0;
    }
  }

  // Disabled state
  //
  // Gray out text and ensure the hover/focus state remains gray

  .dropdown-menu > .disabled > a {
    &,
    &:hover,
    &:focus {
      color: #777;
    }

    // Nuke hover/focus effects
    &:hover,
    &:focus {
      text-decoration: none;
      cursor: not-allowed;
      background-color: transparent;
      background-image: none; // Remove CSS gradient
    }
  }

  // Open state for the dropdown
  .open {
    // Show the menu
    > .dropdown-menu {
      display: block;
    }

    // Remove the outline when :focus is triggered
    > a {
      outline: 0;
    }
  }

  // Menu positioning
  //
  // Add extra class to '.dropdown - menu' to flip the alignment of the dropdown
  // menu with the parent.
  .dropdown-menu-right {
    right: 0;
    left: auto; // Reset the default from '.dropdown -menu'
  }
  // With v3, we enabled auto-flipping if you have a dropdown within a right
  // aligned nav component. To enable the undoing of that, we provide an override
  // to restore the default dropdown menu alignment.
  //
  // This is only for left-aligning a dropdown menu within a '.navbar-right' or
  // '.pull -right' nav component.
  .dropdown-menu-left {
    right: auto;
    left: 0;
  }

  // Dropdown section headers
  .dropdown-header {
    display: block;
    padding: 3px 20px;
    font-size: 12px;
    line-height: 1.428571429;
    color: #777;
    white-space: nowrap; // as with > li > a
  }

  // Backdrop to catch body clicks on mobile, etc.
  .dropdown-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 990;
  }

  // Right aligned dropdowns
  .pull-right > .dropdown-menu {
    right: 0;
    left: auto;
  }

  // Allow for dropdowns to go bottom up (aka, dropup-menu)
  //
  // Just add .dropup after the standard .dropdown class and you're set, bro.
  // TODO: abstract this so that the navbar fixed styles are not placed here?

  .dropup,
  .navbar-fixed-bottom .dropdown {
    // Reverse the caret
    .caret {
      content: '';
      border-top: 0;
      border-bottom: 4px dashed;
    }
    // Different positioning for bottom up menu
    .dropdown-menu {
      top: auto;
      bottom: 100%;
      margin-bottom: 2px;
    }
  }

  // stylelint-disable selector-no-qualifying-type

  //
  // Buttons
  // --------------------------------------------------

  // Base styles
  // --------------------------------------------------

  .btn {
    display: inline-block;
    margin-bottom: 0; // For input.btn
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
    border: 1px solid transparent;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.428571429;
    border-radius: 4px;
    user-select: none;

    &,
    &:active,
    &.active {
      &:focus,
      &.focus {
        outline: 5px auto -webkit-focus-ring-color;
        outline-offset: -2px;
      }
    }

    &:hover,
    &:focus,
    &.focus {
      color: #333;
      text-decoration: none;
    }

    &:active,
    &.active {
      background-image: none;
      outline: 0;
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    }

    &.disabled,
    &[disabled],
    fieldset[disabled] & {
      cursor: not-allowed;
      opacity: 0.65;
      box-shadow: none;
    }

    a& {
      &.disabled,
      fieldset[disabled] & {
        pointer-events: none; // Future-proof disabling of clicks on '<a>' elements
      }
    }
  }

  // Alternate buttons
  // --------------------------------------------------

  .btn-default {
    ${buttonVariant('#333', '#fff', '#ccc')}
  }
  .btn-primary {
    ${buttonVariant('#fff', bootstrapPrimary, darken(0.05, bootstrapPrimary))}
  }
  // Success appears as green
  .btn-success {
    ${buttonVariant('#fff', '#5cb85c', darken(0.05, '#5cb85c'))}
  }
  // Info appears as blue-green
  .btn-info {
    ${buttonVariant('#fff', '#5bc0de', darken(0.05, '#5bc0de'))}
  }
  // Warning appears as orange
  .btn-warning {
    ${buttonVariant('#fff', '#f0ad4e', darken(0.05, '#f0ad4e'))}
  }
  // Danger and error appear as red
  .btn-danger {
    ${buttonVariant('#fff', '#d9534f', darken(0.05, '#d9534f'))}
  }

  // Link buttons
  // -------------------------

  // Make a button look and behave like a link
  .btn-link {
    font-weight: 400;
    color: ${bootstrapPrimary};
    border-radius: 0;

    &,
    &:active,
    &.active,
    &[disabled],
    fieldset[disabled] & {
      background-color: transparent;
      box-shadow: none;
    }
    &,
    &:hover,
    &:focus,
    &:active {
      border-color: transparent;
    }
    &:hover,
    &:focus {
      color: ${darken(0.15, bootstrapPrimary)};
      text-decoration: underline;
      background-color: transparent;
    }
    &[disabled],
    fieldset[disabled] & {
      &:hover,
      &:focus {
        color: #777;
        text-decoration: none;
      }
    }
  }

  // Button Sizes
  // --------------------------------------------------

  .btn-lg {
    // line-height: ensure even-numbered height of button next to large input
    ${buttonSize('10px', '16px', '18px', '1.3333333', '6px')}
  }
  .btn-sm {
    // line-height: ensure proper height of button next to small input
    ${buttonSize('6px', '12px', '12px', '1.5', '3px')}
  }
  .btn-xs {
    ${buttonSize('6px', '12px', '12px', '1.5', '3px')}
  }

  // Block button
  // --------------------------------------------------

  .btn-block {
    display: block;
    width: 100%;
  }

  // Vertically space out multiple block buttons
  .btn-block + .btn-block {
    margin-top: 5px;
  }

  // Specificity overrides
  input[type='submit'],
  input[type='reset'],
  input[type='button'] {
    &.btn-block {
      width: 100%;
    }
  }
  //
  // Panels
  // --------------------------------------------------

  // Base class
  .panel {
    margin-bottom: 20px;
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  }

  // Panel contents
  .panel-body {
    padding: 15px;

    &:before,
    &:after {
      display: table; // 2
      content: ' '; // 1
    }
    &:after {
      clear: both;
    }
  }

  // Optional heading
  .panel-heading {
    padding: 10px 15px;
    border-bottom: 1px solid transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;

    > .dropdown .dropdown-toggle {
      color: inherit;
    }
  }

  // Within heading, strip any 'h*' tag of its default margins for spacing.
  .panel-title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 16px;
    color: inherit;

    > a,
    > small,
    > .small,
    > small > a,
    > .small > a {
      color: inherit;
    }
  }

  // Optional footer (stays gray in every modifier class)
  .panel-footer {
    padding: 10px 15px;
    background-color: #f5f5f5;
    border-top: 1px solid #ddd;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  // List groups in panels
  //
  // By default, space out list group content from panel headings to account for
  // any kind of custom content between the two.

  .panel {
    > .list-group,
    > .panel-collapse > .list-group {
      margin-bottom: 0;

      .list-group-item {
        border-width: 1px 0;
        border-radius: 0;
      }

      // Add border top radius for first one
      &:first-child {
        .list-group-item:first-child {
          border-top: 0;
          border-top-left-radius: 3px;
          border-top-right-radius: 3px;
        }
      }

      // Add border bottom radius for last one
      &:last-child {
        .list-group-item:last-child {
          border-bottom: 0;
          border-bottom-left-radius: 3px;
          border-bottom-right-radius: 3px;
        }
      }
    }
    > .panel-heading + .panel-collapse > .list-group {
      .list-group-item:first-child {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }
  // Collapse space between when there's no additional content.
  .panel-heading + .list-group {
    .list-group-item:first-child {
      border-top-width: 0;
    }
  }
  .list-group + .panel-footer {
    border-top-width: 0;
  }

  // Tables in panels
  //
  // Place a non-bordered '.table' within a panel (not within a '.panel-body') and
  // watch it go full width.

  .panel {
    > .table,
    > .table-responsive > .table,
    > .panel-collapse > .table {
      margin-bottom: 0;

      caption {
        padding-right: 15px;
        padding-left: 15px;
      }
    }
    // Add border top radius for first one
    > .table:first-child,
    > .table-responsive:first-child > .table:first-child {
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;

      > thead:first-child,
      > tbody:first-child {
        > tr:first-child {
          border-top-left-radius: 3px;
          border-top-right-radius: 3px;

          td:first-child,
          th:first-child {
            border-top-left-radius: 3px;
          }
          td:last-child,
          th:last-child {
            border-top-right-radius: 3px;
          }
        }
      }
    }
    // Add border bottom radius for last one
    > .table:last-child,
    > .table-responsive:last-child > .table:last-child {
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;

      > tbody:last-child,
      > tfoot:last-child {
        > tr:last-child {
          border-bottom-right-radius: 3px;
          border-bottom-left-radius: 3px;

          td:first-child,
          th:first-child {
            border-bottom-left-radius: 3px;
          }
          td:last-child,
          th:last-child {
            border-bottom-right-radius: 3px;
          }
        }
      }
    }
    > .panel-body + .table,
    > .panel-body + .table-responsive,
    > .table + .panel-body,
    > .table-responsive + .panel-body {
      border-top: 1px solid #ddd;
    }
    > .table > tbody:first-child > tr:first-child th,
    > .table > tbody:first-child > tr:first-child td {
      border-top: 0;
    }
    > .table-bordered,
    > .table-responsive > .table-bordered {
      border: 0;
      > thead,
      > tbody,
      > tfoot {
        > tr {
          > th:first-child,
          > td:first-child {
            border-left: 0;
          }
          > th:last-child,
          > td:last-child {
            border-right: 0;
          }
        }
      }
      > thead,
      > tbody {
        > tr:first-child {
          > td,
          > th {
            border-bottom: 0;
          }
        }
      }
      > tbody,
      > tfoot {
        > tr:last-child {
          > td,
          > th {
            border-bottom: 0;
          }
        }
      }
    }
    > .table-responsive {
      margin-bottom: 0;
      border: 0;
    }
  }

  // Collapsible panels (aka, accordion)
  //
  // Wrap a series of panels in '.panel-group' to turn them into an accordion with
  // the help of our collapse JavaScript plugin.

  .panel-group {
    margin-bottom: 20px;

    // Tighten up margin so it's only between panels
    .panel {
      margin-bottom: 0;
      border-radius: 4px;

      + .panel {
        margin-top: 5px;
      }
    }

    .panel-heading {
      border-bottom: 0;

      + .panel-collapse > .panel-body,
      + .panel-collapse > .list-group {
        border-top: 1px solid #ddd;
      }
    }

    .panel-footer {
      border-top: 0;
      + .panel-collapse .panel-body {
        border-bottom: 1px solid #ddd;
      }
    }
  }

  // Contextual variations
  .panel-default {
    ${panelVariant('#ddd', '#222', '#f5f5f5', '#ddd')}
  }
  .panel-primary {
    ${panelVariant(bootstrapPrimary, '#fff', bootstrapPrimary, bootstrapPrimary)}
  }
  //
  // Button groups
  // --------------------------------------------------

  // Make the div behave like a button
  .btn-group,
  .btn-group-vertical {
    position: relative;
    display: inline-block;
    vertical-align: middle; // match .btn alignment given font-size hack above
    > .btn {
      position: relative;
      float: left;
      // Bring the "active" button to the front
      &:hover,
      &:focus,
      &:active,
      &.active {
        z-index: 2;
      }
    }
  }

  // Prevent double borders when buttons are next to each other
  .btn-group {
    .btn + .btn,
    .btn + .btn-group,
    .btn-group + .btn,
    .btn-group + .btn-group {
      margin-left: -1px;
    }
  }

  // Optional: Group multiple button groups together for a toolbar
  .btn-toolbar {
    margin-left: -5px; // Offset the first child's margin

    .btn,
    .btn-group,
    .input-group {
      float: left;
    }
    > .btn,
    > .btn-group,
    > .input-group {
      margin-left: 5px;
    }
  }

  .btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
    border-radius: 0;
  }

  // Set corners individual because sometimes a single button can be in a .btn-group and we need :first-child and :last-child to both match
  .btn-group > .btn:first-child {
    margin-left: 0;
    &:not(:last-child):not(.dropdown-toggle) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  // Need .dropdown-toggle since :last-child doesn't apply, given that a .dropdown-menu is used immediately after it
  .btn-group > .btn:last-child:not(:first-child),
  .btn-group > .dropdown-toggle:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  // Custom edits for including btn-groups within btn-groups (useful for including dropdown buttons within a btn-group)
  .btn-group > .btn-group {
    float: left;
  }
  .btn-group > .btn-group:not(:first-child):not(:last-child) > .btn {
    border-radius: 0;
  }
  .btn-group > .btn-group:first-child:not(:last-child) {
    > .btn:last-child,
    > .dropdown-toggle {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  .btn-group > .btn-group:last-child:not(:first-child) > .btn:first-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  // On active and open, don't show outline
  .btn-group .dropdown-toggle:active,
  .btn-group.open .dropdown-toggle {
    outline: 0;
  }

  // Sizing
  //
  // Remix the default button sizing classes into new ones for easier manipulation.

  .btn-group-xs > .btn {
    ${buttonSize('6px', '12px', '12px', '1.5', '3px')}
  }
  .btn-group-sm > .btn {
    ${buttonSize('6px', '12px', '12px', '1.5', '3px')}
  }
  .btn-group-lg > .btn {
    ${buttonSize('10px', '16px', '18px', '1.3333333', '6px')}
  }

  // Split button dropdowns
  // ----------------------

  // Give the line between buttons some depth
  .btn-group > .btn + .dropdown-toggle {
    padding-right: 8px;
    padding-left: 8px;
  }
  .btn-group > .btn-lg + .dropdown-toggle {
    padding-right: 12px;
    padding-left: 12px;
  }

  // The clickable button for toggling the menu
  // Remove the gradient and set the same inset shadow as the :active state
  .btn-group.open .dropdown-toggle {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);

    // Show no shadow for '.btn-link' since it has no other button styles.
    &.btn-link {
      box-shadow: none;
    }
  }

  // Reposition the caret
  .btn .caret {
    margin-left: 0;
  }
  // Carets in other button sizes
  .btn-lg .caret {
    border-width: 5px 5px 0;
    border-bottom-width: 0;
  }
  // Upside down carets for .dropup
  .dropup .btn-lg .caret {
    border-width: 0 5px 5px;
  }

  // Vertical button groups
  // ----------------------

  .btn-group-vertical {
    > .btn,
    > .btn-group,
    > .btn-group > .btn {
      display: block;
      float: none;
      width: 100%;
      max-width: 100%;
    }

    // Clear floats so dropdown menus can be properly placed
    > .btn-group {
      > .btn {
        float: none;
      }
    }

    > .btn + .btn,
    > .btn + .btn-group,
    > .btn-group + .btn,
    > .btn-group + .btn-group {
      margin-top: -1px;
      margin-left: 0;
    }
  }

  .btn-group-vertical > .btn {
    &:not(:first-child):not(:last-child) {
      border-radius: 0;
    }
    &:first-child:not(:last-child) {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    &:last-child:not(:first-child) {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
  .btn-group-vertical > .btn-group:not(:first-child):not(:last-child) > .btn {
    border-radius: 0;
  }
  .btn-group-vertical > .btn-group:first-child:not(:last-child) {
    > .btn:last-child,
    > .dropdown-toggle {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  .btn-group-vertical > .btn-group:last-child:not(:first-child) > .btn:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  // Justified button groups
  // ----------------------

  .btn-group-justified {
    display: table;
    width: 100%;
    table-layout: fixed;
    border-collapse: separate;
    > .btn,
    > .btn-group {
      display: table-cell;
      float: none;
      width: 1%;
    }
    > .btn-group .btn {
      width: 100%;
    }

    > .btn-group .dropdown-menu {
      left: auto;
    }
  }

  // Checkbox and radio options
  //
  // In order to support the browser's form validation feedback, powered by the
  // 'required' attribute, we have to "hide" the inputs via 'clip'. We cannot use
  // 'display: none;' or 'visibility: hidden;' as that also hides the popover.
  // Simply visually hiding the inputs via 'opacity' would leave them clickable in
  // certain cases which is prevented by using 'clip' and 'pointer-events'.
  // This way, we ensure a DOM element is visible to position the popover from.
  //
  // See https://github.com/twbs/bootstrap/pull/12794 and
  // https://github.com/twbs/bootstrap/pull/14559 for more information.

  [data-toggle='buttons'] {
    > .btn,
    > .btn-group > .btn {
      input[type='radio'],
      input[type='checkbox'] {
        position: absolute;
        clip: rect(0, 0, 0, 0);
        pointer-events: none;
      }
    }
  }
  //
  // Tooltips
  // --------------------------------------------------

  // Base class
  .tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    // Our parent element can be arbitrary since tooltips are by default inserted as a sibling of their target element.
    // So reset our font and text properties to avoid inheriting weird values.

    // We deliberately do NOT reset font-size.
    font-style: normal;
    font-weight: 400;
    line-break: auto;
    text-align: left; // Fallback for where 'start' is not supported
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;
    white-space: normal;
    font-size: 12px;
    opacity: 0;

    &.in {
      opacity: 0.9;
    }
    &.top {
      padding: 5px 0;
      margin-top: -3px;
    }
    &.right {
      padding: 0 5px;
      margin-left: 3px;
    }
    &.bottom {
      padding: 5px 0;
      margin-top: 3px;
    }
    &.left {
      padding: 0 5px;
      margin-left: -3px;
    }

    // Note: Deprecated .top-left, .top-right, .bottom-left, and .bottom-right as of v3.3.1
    &.top .tooltip-arrow {
      bottom: 0;
      left: 50%;
      margin-left: -5px;
      border-width: 5px 5px 0;
      border-top-color: #000;
    }
    &.top-left .tooltip-arrow {
      right: 5px;
      bottom: 0;
      margin-bottom: -5px;
      border-width: 5px 5px 0;
      border-top-color: #000;
    }
    &.top-right .tooltip-arrow {
      bottom: 0;
      left: 5px;
      margin-bottom: -5px;
      border-width: 5px 5px 0;
      border-top-color: #000;
    }
    &.right .tooltip-arrow {
      top: 50%;
      left: 0;
      margin-top: -5px;
      border-width: 5px 5px 5px 0;
      border-right-color: #000;
    }
    &.left .tooltip-arrow {
      top: 50%;
      right: 0;
      margin-top: -5px;
      border-width: 5px 0 5px 5px;
      border-left-color: #000;
    }
    &.bottom .tooltip-arrow {
      top: 0;
      left: 50%;
      margin-left: -5px;
      border-width: 0 5px 5px;
      border-bottom-color: #000;
    }
    &.bottom-left .tooltip-arrow {
      top: 0;
      right: 5px;
      margin-top: -5px;
      border-width: 0 5px 5px;
      border-bottom-color: #000;
    }
    &.bottom-right .tooltip-arrow {
      top: 0;
      left: 5px;
      margin-top: -5px;
      border-width: 0 5px 5px;
      border-bottom-color: #000;
    }
  }

  // Wrapper for the tooltip content
  .tooltip-inner {
    max-width: 200px;
    padding: 3px 8px;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: 4px;
  }

  // Arrows
  .tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
  }
`;
