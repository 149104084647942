import { Select, Col, Form, Row, Modal, Cascader, Rate, Checkbox, message } from 'antd';
import { CONTENTS_INPUTS } from 'constants/inputs';
import useCategory from 'hooks/useCategory';
import { useState } from 'react';
import { useLocation } from 'react-router';

function MultipleModal({ visible, onCancel, onMultipleEdit }) {
  const location = useLocation();
  const [checked, setChecked] = useState({
    category: false,
    difficulty: false,
    access_level: false,
  });
  const [form] = Form.useForm();

  const isVideoPage = location.pathname.includes('video');
  const idNameToValueLabelCategories = useCategory(isVideoPage);

  const handleCheckboxChange = (name) => (e) => {
    setChecked((prevState) => ({
      ...prevState,
      [name]: e.target.checked,
    }));
    if (name === 'category') {
      form.setFieldsValue({
        [name]: e.target.checked ? null : undefined,
      });
    }
    if (name === 'difficulty') {
      form.setFieldsValue({
        [name]: e.target.checked ? 0 : undefined,
      });
    }
    if (name === 'access_level') {
      form.setFieldsValue({
        [name]: e.target.checked ? null : undefined,
      });
    }
  };

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      okButtonProps={{ disabled: !checked.category && !checked.difficulty && !checked.access_level }}
      okText="일괄 수정"
      cancelText="취소"
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            message.success('일괄 수정이 완료되었습니다.');
            onMultipleEdit(values);
          })
          .catch((info) => {
            console.error('Validate Failed:', info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <Row gutter={16}>
          <Col>
            <Checkbox checked={checked.category} onChange={handleCheckboxChange('category')} />
          </Col>
          <Col span={18}>
            <Form.Item
              label={CONTENTS_INPUTS.CATEGORY.label}
              name={CONTENTS_INPUTS.CATEGORY.name}
              rules={checked.category && CONTENTS_INPUTS.CATEGORY.rules}
            >
              <Cascader
                placeholder={CONTENTS_INPUTS.CATEGORY.placeholder}
                options={idNameToValueLabelCategories}
                disabled={!checked.category}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col>
            <Checkbox checked={checked.difficulty} onChange={handleCheckboxChange('difficulty')} />
          </Col>
          <Col span={12}>
            <Form.Item
              label={CONTENTS_INPUTS.DIFFICULTY.label}
              name={CONTENTS_INPUTS.DIFFICULTY.name}
              rules={checked.difficulty && CONTENTS_INPUTS.DIFFICULTY.rules}
            >
              <Rate count={3} disabled={!checked.difficulty} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col>
            <Checkbox checked={checked.access_level} onChange={handleCheckboxChange('access_level')} />
          </Col>
          <Col span={12}>
            <Form.Item
              label={CONTENTS_INPUTS.ACCESS_LEVEL.label}
              name={CONTENTS_INPUTS.ACCESS_LEVEL.name}
              rules={checked.access_level && CONTENTS_INPUTS.ACCESS_LEVEL.rules}
            >
              <Select
                options={CONTENTS_INPUTS.ACCESS_LEVEL.options}
                placeholder={CONTENTS_INPUTS.ACCESS_LEVEL.placeholder}
                disabled={!checked.access_level}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default MultipleModal;
