import api from 'api';
import { camelize } from 'utils/obj';

export const requestAddDailySchedule = async (params) => {
  const { data, error } = await api({
    method: 'post',
    url: '/daily-schedule',
    data: params,
  });

  return {
    data: data ? camelize(data) : null,
    error,
    requestName: 'DAILY_SCHEDULE_CREATE',
  };
};

export const requestDeleteDailySchedule = async (params) => {
  const { data, error } = await api({
    method: 'delete',
    url: `/daily-schedule/${params?.id}`,
  });

  return {
    data: data ? camelize(data) : null,
    error,
    requestName: 'DAILY_SCHEDULE_DELETE',
  };
};

export const requestAddWeeklySchedule = async (params) => {
  const { data, error } = await api({
    method: 'post',
    url: '/weekly-schedule',
    data: params,
  });

  return {
    data: data ? camelize(data) : null,
    error,
    requestName: 'WEEKLY_SCHEDULE_CREATE',
  };
};

export const requestDeleteWeeklySchedule = async (params) => {
  const { data, error } = await api({
    method: 'delete',
    url: `/weekly-schedule/${params?.id}`,
  });

  return {
    data: data ? camelize(data) : null,
    error,
    requestName: 'WEEKLY_SCHEDULE_DELETE',
  };
};
