import { Layout } from 'antd';
import Header from 'components/common/Header';
import Nav from 'components/common/Nav';
import { Route } from 'react-router-dom';

const { Content, Footer } = Layout;

function BaseLayoutWithRoute({ title, component: Component, componentProps, path, ...rest }) {
  return (
    <Layout>
      <Route path={path} {...rest}>
        {(props) => (
          <>
            <Nav />
            <Layout>
              <Header title={title} />
              <Content style={{ margin: '24px 16px 0' }}>
                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                  <Component {...props} {...componentProps} />
                </div>
              </Content>
              <Footer style={{ textAlign: 'center' }}>돌봄 관리자</Footer>
            </Layout>
          </>
        )}
      </Route>
    </Layout>
  );
}

export default BaseLayoutWithRoute;
