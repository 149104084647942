import { pipe, replace } from 'ramda';

import { camelcase, snakecase } from './str';

export const snakize = (source) =>
  pipe(
    JSON.stringify,
    replace(/[{|,]\s?["]?(\w+[A-Z\-\s]\w+)["]?:/g, ($1, $2) => $1.replace($2, snakecase($2))),
    JSON.parse
  )(source);

export const camelize = (source) =>
  pipe(
    JSON.stringify,
    replace(/[{|,]\s?["]?(\w+[-_\s]\w+)["]?:/g, ($1, $2) => $1.replace($2, camelcase($2))),
    JSON.parse
  )(source);
