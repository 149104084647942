import { patchPost } from 'api/requests/boardRequests';
import BoardForm from 'components/board/BoardForm';
import { useHistory, useParams } from 'react-router-dom';
import useSWR from 'swr';
import { camelize } from 'utils/obj';

export default function BoardEditPage() {
  const history = useHistory();
  const { id } = useParams();
  const { data: _data, mutate } = useSWR(`/dolbom-board/${id}`);

  if (!_data) {
    return null;
  }
  const data = camelize(_data);

  return (
    <>
      <BoardForm
        title={data.title}
        content={data.content}
        isNotice={data.isNotice}
        startDate={data.startDate}
        endDate={data.endDate}
        isEditMode={true}
        onSubmit={(formData) => {
          patchPost({ id, ...formData }).then(() => {
            if (!formData.startDate) {
              delete formData.startDate;
            }
            if (!formData.endDate) {
              delete formData.endDate;
            }
            mutate();
            history.push(`/board/${id}`);
          });
        }}
      />
    </>
  );
}
