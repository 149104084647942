import { Select, Col, Input, Row, Table, message } from 'antd';
import { routePaths } from 'constants/pathMap';
import qs from 'query-string';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import useSWR from 'swr';
import { camelize } from 'utils/obj';

const columns = [
  {
    title: '아이디',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '이름',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '등급',
    dataIndex: 'level',
    key: 'level',
    render: (level) => (level === 2 ? '일반' : level === 5 ? '프리미엄' : '어드민'),
  },
  {
    title: '이메일',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: '전화번호',
    dataIndex: 'hp',
    key: 'hp',
  },
  {
    title: '학교명',
    dataIndex: 'schoolName',
    key: 'schoolName',
  },
  {
    title: '반',
    dataIndex: 'schoolClassName',
    key: 'schoolClassName',
  },
];

const DEFAULT_PAGE_SIZE = 10;
function UserListPage() {
  const history = useHistory();
  const location = useLocation();

  const searchParams = qs.parse(location.search);

  const [page, setPage] = useState(searchParams?.page ? +searchParams?.page : 0);
  const [pageSize] = useState(DEFAULT_PAGE_SIZE);
  const [searchType, setSearchType] = useState('name');
  const [keyword, setKeyword] = useState(null);
  const offset = useMemo(() => (page === 0 ? 0 : page * pageSize), [page, pageSize]);
  const { data } = useSWR(
    searchType?.length > 0 && keyword?.length > 0
      ? `/account?offset=${offset}&limit=${pageSize}&search_type=${searchType}&search_keyword=${keyword ?? ''}`
      : `/account?offset=${offset}&limit=${pageSize}`
  );

  const handleRow = (record, rowIndex) => {
    return {
      onClick: (event) => {
        if (record.isDolbomAccount) {
          history.push(`/user/${record.id}`);
          return;
        }
        message.error('이 유저는 돌봄 유저가 아닙니다.');
      },
    };
  };

  const userList = useMemo(() => (data?.list ? camelize(data.list[0]) : []), [data]);

  const paginationConfig = {
    total: data?.total,
    showSizeChanger: false,
    pageSize: DEFAULT_PAGE_SIZE,
    current: page + 1,
  };
  const handleSearchTypeChange = (type) => {
    setSearchType(type);
  };
  const handleSearch = (searchValue) => {
    window.history.replaceState(null, '', `${routePaths.USER}?page=0`);
    setPage(0);
    setKeyword(searchValue);
  };

  const handleChange = (pagination, filters, sorter, extra) => {
    if (extra.action === 'paginate') {
      history.push(`${routePaths.USER}?page=${pagination.current - 1}`);
      setPage(pagination.current - 1);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [searchParams?.page]);

  return (
    <div>
      <Row style={{ paddingBottom: 12 }}>
        <Col>
          <Select
            value={searchType}
            placeholder="검색 조건"
            style={{ width: 120 }}
            onChange={handleSearchTypeChange}
            options={[
              { label: '아이디', value: 'id' },
              { label: '이메일', value: 'email' },
              { label: '이름', value: 'name' },
              { label: '핸드폰', value: 'hp' },
              { label: '학교명', value: 'school_name' },
            ]}
          />
        </Col>
        <Col span={8}>
          <Input.Search onSearch={handleSearch} />
        </Col>
      </Row>
      {data && (
        <Table
          onChange={handleChange}
          rowKey="id"
          columns={columns}
          dataSource={userList}
          onRow={handleRow}
          pagination={paginationConfig}
        />
      )}
    </div>
  );
}

export default UserListPage;
