import { Badge, Col, Row, Select } from 'antd';
import Calendar from 'components/common/Calendar';
import DailyContentsListModal from 'components/monthly-contents/DailyContentsListModal';
import WeeklyContentsListModal from 'components/monthly-contents/WeeklyContentsListModal';
import { MONTHS, YEARS } from 'constants/date';
import dayjs from 'dayjs';
import { useState } from 'react';
import styled from 'styled-components';
import useSWR from 'swr';
import { getWeek } from 'utils/date';

const dayjsInstance = dayjs();
function MonthlyContentsPage() {
  const [dailyModalVisible, setDailyModalVisible] = useState(false);
  const [weeklyModalVisible, setWeeklyModalVisible] = useState(false);
  const [selectedDayContentsList, setSelectedDayContentsList] = useState([]);
  const [selectedDay, setSelectedDay] = useState('');
  const [selectedWeekContentsList, setSelectedWeekContentsList] = useState([]);
  const [selectedWeek, setSelectedWeek] = useState('');
  const [date, setDate] = useState({
    year: dayjsInstance.year(),
    month: dayjsInstance.month(),
    day: dayjsInstance.date(),
  });
  // 12, 1, 2 // 1, 2, 3
  const { data: prevMonthData, mutate: prevMonthMutate } = useSWR(
    `/schedule?year=${date.month === 0 ? date.year - 1 : date.year}&month=${date.month === 0 ? 12 : date.month}`
  );
  const { data: currentMonthData, mutate: currentMonthMutate } = useSWR(
    `/schedule?year=${date.year}&month=${date.month + 1}`
  );
  const { data: nextMonthData, mutate: nextMonthMutate } = useSWR(
    date.month + 2 <= 12
      ? `/schedule?year=${date.year}&month=${date.month + 2}`
      : `/schedule?year=${date.year + 1}&month=${Math.floor((date.month + 2) / 12)}`
  );

  const getContentsByDate = (targetDate) => {
    const fullMonthData = [prevMonthData, currentMonthData, nextMonthData].filter((data) => data != null);
    // 모든 데이터 fetch 안됐으면 리턴
    if (fullMonthData.length < 3) {
      return;
    }
    const day = targetDate.date(); // 오늘 몇일인지
    const weekCount = getWeek(targetDate);

    const dateMonth = targetDate.month(); // 타겟 값의 month
    const currentMonth = date.month; // 현재 달력 month
    const monthIndex =
      currentMonth === dateMonth
        ? 1
        : (currentMonth < dateMonth || (currentMonth === 11 && dateMonth === 0)) &&
          !(currentMonth === 0 && dateMonth === 11)
        ? 2
        : 0;

    const monthData = fullMonthData[monthIndex].list;

    const weekData = monthData ? monthData[weekCount] : [];
    const contents = weekData?.daily?.find((dailyDatas) => dailyDatas.date === day)?.contents;

    return contents;
  };

  const mutateAll = () => {
    prevMonthMutate();
    currentMonthMutate();
    nextMonthMutate();
  };

  const openDailyModal = () => {
    setDailyModalVisible(true);
  };

  const closeDailyModal = () => {
    setDailyModalVisible(false);
  };
  const openWeeklyModal = () => {
    setWeeklyModalVisible(true);
  };

  const closeWeeklyModal = () => {
    setWeeklyModalVisible(false);
  };

  const handleDailySelect = (targetDate) => {
    setDate((prevDate) => ({
      ...prevDate,
      day: targetDate.date(),
    }));

    const contents = getContentsByDate(targetDate);

    setSelectedDayContentsList(contents);
    openDailyModal();
    setSelectedDay(targetDate.format('YYYY-MM-DD'));
  };

  const handleWeeklySelect = (weeklyContentsList, weekNum) => {
    setSelectedWeekContentsList(weeklyContentsList);
    setSelectedWeek(weekNum);
    openWeeklyModal();
  };

  const handleYearMonthChange = ({ value }, type) => {
    if (type === 'year') {
      setDate((prevDate) => ({
        ...prevDate,
        year: +value,
      }));
    }
    if (type === 'month') {
      setDate((prevDate) => ({
        ...prevDate,
        month: +value,
      }));
    }
  };

  const dateCellRender = (targetDate) => {
    // 토, 일 건너뜀
    if (targetDate.format('dd') === 'Su' || targetDate.format('dd') === 'Sa') {
      return;
    }

    const contents = getContentsByDate(targetDate) || [];

    return (
      <ul className="events">
        {contents.map((item, i) => (
          <li key={i}>
            <Badge
              color={
                item.category2_name === '프로그램' ? '#EB7EFF' : item.category2_name === '클립' ? '#1DDB5A' : '#FF352B'
              }
              text={item.title}
            />
          </li>
        ))}
      </ul>
    );
  };

  const renderWeeklyContents = () => {
    const sixArray = new Array(6).fill(true);
    const startDateOfMonth = dayjs(`${date.year}-${date.month + 1}-${date.day}`)
      .clone()
      .startOf('month');
    const weeklyRenderable = sixArray.map((_, index) => {
      if (index === 0 && dayjs(startDateOfMonth).date(1).get('day') >= 6) {
        return false;
      }
      return true;
    });

    const list = [];
    let isWeekCorrect = true;
    if (weeklyRenderable[0] === false) {
      list.push(<WeeklyContents style={{ opacity: 0, cursor: 'auto' }} />);
      isWeekCorrect = false;
    }
    const weeklyArr = currentMonthData?.list ?? [];
    weeklyArr.forEach(({ weekly }, i) => {
      const newIndex = isWeekCorrect ? i : i + 1;
      list.push(
        weeklyRenderable[newIndex] === true && (
          <WeeklyContents
            onClick={() => {
              handleWeeklySelect(weekly, newIndex);
            }}
            key={i}
          >
            <h1>{isWeekCorrect ? newIndex + 1 : newIndex}주차</h1>
            <ul className="events">
              {weekly.map((contents) => (
                <li key={contents.id}>
                  <Badge color="rgb(50, 50, 50, 0.75)" text={contents.title} />
                </li>
              ))}
            </ul>
          </WeeklyContents>
        )
      );
    });
    return list;
  };

  return (
    <>
      <Background>
        <Row gutter={16} style={{ padding: '16px 0' }}>
          <Col>
            <Select
              style={{ width: '100%' }}
              placeholder="년도"
              onChange={(value) => handleYearMonthChange(value, 'year')}
              options={YEARS}
              labelInValue={true}
              defaultValue={{ label: `${date.year}년`, value: date.year }}
            />
          </Col>
          <Col>
            <Select
              style={{ width: '100%' }}
              placeholder="월"
              onChange={(value) => handleYearMonthChange(value, 'month')}
              options={MONTHS}
              labelInValue={true}
              defaultValue={{ label: `${date.month + 1}월`, value: date.month }}
            />
          </Col>
        </Row>
        <div style={{ display: 'flex' }}>
          <Calendar
            dateCellRender={dateCellRender}
            style={{ width: 960 }}
            mode="month"
            headerRender={() => {}}
            onSelect={(date) => {
              handleDailySelect(date);
            }}
            value={dayjs(`${date.year}-${date.month + 1}-${date.day}`)}
          />
          <div style={{ position: 'relative', marginLeft: 24, flex: 1, paddingTop: 32 }}>{renderWeeklyContents()}</div>
        </div>
      </Background>
      {dailyModalVisible && (
        <DailyContentsListModal
          visible={dailyModalVisible}
          onCancel={() => {
            closeDailyModal();
            mutateAll();
          }}
          selectedDayContentsList={selectedDayContentsList}
          selectedDay={selectedDay}
          maxContents={3}
          mutateAll={mutateAll}
        />
      )}
      {weeklyModalVisible && (
        <WeeklyContentsListModal
          visible={weeklyModalVisible}
          onCancel={() => {
            closeWeeklyModal();
            mutateAll();
          }}
          selectedWeekContentsList={selectedWeekContentsList}
          selectedYear={date.year}
          selectedMonth={date.month}
          selectedWeekNum={selectedWeek}
          maxContents={2}
        />
      )}
    </>
  );
}

const Background = styled.div`
  position: relative;
  padding: 12px;
  background-color: #fff;
  .ant-picker-calendar-header .ant-radio-group {
    display: none !important;
  }
  table.ant-picker-content > thead > tr {
    th:first-child,
    th:last-child {
      display: none;
    }
  }
  table.ant-picker-content > tbody > tr {
    td:first-child,
    td:last-child {
      display: none;
    }
  }

  ul {
    list-style: none;
  }
  ul,
  li {
    padding: 0;
    margin: 0;
  }
  li > .ant-badge {
    display: flex;
    align-items: center;
  }
  li > .ant-badge > .ant-badge-status-text {
    display: block;
    width: 140px;
    font-size: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

const WeeklyContents = styled.div`
  margin-bottom: 16px;
  width: 180px;
  height: 100px;
  border: 1px solid #e9e9e9;
  border-radius: 8px;
  padding: 8px;
  box-shadow: 0.5px 0.5px 0.5px 1px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5;
  }

  h1 {
    text-align: center;
  }
`;

export default MonthlyContentsPage;
