import { patchEvent } from 'api/requests/eventRequests';
import EventForm from 'components/event/EventForm';
import { useHistory, useParams } from 'react-router-dom';
import useSWR from 'swr';
import { camelize } from 'utils/obj';

export default function BannerEventEditPage() {
  const history = useHistory();
  const { id } = useParams();
  const { data: _data, mutate } = useSWR(`/event/${id}`);

  if (!_data) {
    return null;
  }
  const data = camelize(_data);

  return (
    <EventForm
      data={data}
      isEditMode={true}
      onSubmit={(value) => {
        patchEvent({ id, ...value }).then(() => {
          mutate();
          history.push('/banner-event');
        });
      }}
    />
  );
}
