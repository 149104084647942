import { Button, Modal, Popover } from 'antd';
import * as R from 'ramda';
import { memo, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import CreateModal from './CreateModal';
import EditModal from './EditModal';

function TreeTitle({ nodeData, createCategory, editCategory, deleteCategory }) {
  const [popOverVisible, setPopoverVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [createModalVisible, setCreateModalVisible] = useState(false);

  const handleCreateCategory = useCallback(
    async (values) => {
      const reformedValues = R.pipe(
        R.map((value) => {
          const hasFile = R.has('file');
          return typeof value === 'undefined' ? '' : hasFile(value) ? value.file.response.url : value;
        })
      )(values);

      const success = await createCategory({ parentId: nodeData.key, ...reformedValues });
      if (success) {
        setCreateModalVisible(false);
      }
    },
    [createCategory, nodeData.key]
  );
  const handleEditCategory = useCallback(
    async (values) => {
      const reformedValues = R.pipe(
        R.evolve({
          thumbnail_url: (value) => {
            return value?.file?.response?.url ?? value;
          },
        })
      )(values);

      const success = await editCategory({ id: nodeData.key, ...reformedValues });
      if (success) {
        setEditModalVisible(false);
        return true;
      }
    },
    [editCategory, nodeData.key]
  );

  const handleDeleteCategory = useCallback(() => {
    setPopoverVisible(false);
    Modal.confirm({
      title: `${nodeData.title} 카테고리를 정말 삭제하시겠습니까?`,
      okText: '삭제',
      okButtonProps: {
        danger: true,
      },
      cancelText: '취소',
      onOk: () => {
        deleteCategory({ id: nodeData.key, title: nodeData.title });
      },
    });
  }, [deleteCategory, nodeData]);

  const isCreateAllowed = useMemo(() => {
    return nodeData.title === '퀴즈' || (nodeData?.depth < 3 && nodeData.key !== 1);
  }, [nodeData]);

  const isEditAndDeleteAllowed = useMemo(() => {
    return (
      nodeData?.title !== '퀴즈' && (nodeData?.parent_key === 32 || nodeData?.depth === 2 || nodeData?.depth === 3)
    );
  }, [nodeData]);

  const renderPopoverContent = useCallback(() => {
    return (
      <PopoverContentsWrap>
        {isCreateAllowed && (
          <Button
            type="primary"
            onClick={() => {
              setPopoverVisible(false);
              setCreateModalVisible(true);
            }}
          >
            하위에 카테고리 추가
          </Button>
        )}
        {isEditAndDeleteAllowed && (
          <>
            <Button
              onClick={() => {
                setPopoverVisible(false);
                setEditModalVisible(true);
              }}
            >
              이 카테고리 수정
            </Button>
            <Button danger onClick={handleDeleteCategory}>
              이 카테고리 삭제
            </Button>
          </>
        )}
      </PopoverContentsWrap>
    );
  }, [handleDeleteCategory, isCreateAllowed, isEditAndDeleteAllowed]);

  return (
    <>
      {nodeData?.title !== '영상' ? (
        <Popover
          placement="right"
          title={nodeData.title}
          content={renderPopoverContent}
          trigger="click"
          visible={popOverVisible}
          onVisibleChange={setPopoverVisible}
        >
          <InnerTreeTitle>{nodeData.title}</InnerTreeTitle>
        </Popover>
      ) : (
        <InnerTreeTitle>{nodeData.title}</InnerTreeTitle>
      )}
      {editModalVisible && (
        <EditModal
          visible={editModalVisible}
          onCancel={() => {
            setEditModalVisible(false);
          }}
          initialValues={{
            name: nodeData.title,
            thumbnail_url: nodeData?.thumbnail_url,
          }}
          onEdit={handleEditCategory}
        />
      )}
      {createModalVisible && (
        <CreateModal
          visible={createModalVisible}
          onCancel={() => {
            setCreateModalVisible(false);
          }}
          onCreate={handleCreateCategory}
        />
      )}
    </>
  );
}

const InnerTreeTitle = styled.span`
  padding-right: 12px;
`;

const PopoverContentsWrap = styled.div`
  display: flex;
  flex-direction: column;
  button {
    margin: 4px 0;
  }
`;

export default memo(TreeTitle);
