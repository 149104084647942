import api from 'api';
import { snakize } from 'utils/obj';

export const postRecommend = async (params) => {
  const { data, error } = await api({
    method: 'post',
    url: '/recommend',
    data: snakize(params),
  });

  return {
    data,
    error,
    requestName: 'POST_RECOMMEND',
  };
};

export const patchRecommend = async ({ id, ...params }) => {
  const { data, error } = await api({
    method: 'patch',
    url: `/recommend/${id}`,
    data: snakize(params),
  });

  return {
    data,
    error,
    requestName: 'PATCH_RECOMMEND',
  };
};

export const deleteRecommend = async ({ id }) => {
  const { data, error } = await api({
    method: 'delete',
    url: `/recommend/${id}`,
  });

  return {
    data,
    error,
    requestName: 'DELETE_RECOMMEND',
  };
};
