import { DeleteOutlined } from '@ant-design/icons';
import { Table, Button, Modal, message } from 'antd';
import { deleteRecommend as requestDeleteRecommend } from 'api/requests/recommendRequests';
import dayjs from 'dayjs';
import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import useSWR from 'swr';
import { camelize } from 'utils/obj';

const PAGE_SIZE = 25;

export default function RecommendContentPage() {
  const history = useHistory();
  const [page, setPage] = useState(1);
  const { data, mutate } = useSWR(`/recommend?offset=${(page - 1) * PAGE_SIZE}&limit=${PAGE_SIZE}`);

  const handleTableChange = useCallback(
    (pagination, filters, sorter) => {
      if (page !== pagination.current) {
        setPage(pagination.current);
      }
    },
    [page]
  );

  if (!data) {
    return null;
  }

  const handleRow = (record, rowIndex) => {
    return {
      onClick: (event) => {
        history.push(`/recommend-contents/${record.key}`);
      },
    };
  };

  const deleteRecommend = ({ id }) => {
    (async () => {
      const { error } = await requestDeleteRecommend({ id });

      if (error) {
        console.log(error);
        Modal.error({
          title: error.response.data.description || '추천 콘텐츠 삭제에 실패하였습니다.',
        });
        return;
      }

      message.success('추천 콘텐츠가 삭제되었습니다.');
      mutate();
    })();
  };

  const openDeleteConfirmModal = (e, record) => {
    e.stopPropagation();
    Modal.confirm({
      title: '정말로 삭제하시겠습니까?',
      content: `"${record.title.props.children}" 추천 콘텐츠가 삭제됩니다.`,
      okText: '삭제하기',
      cancelText: '취소하기',
      okButtonProps: {
        type: 'danger',
      },
      onOk: () => deleteRecommend({ id: record.key }),
    });
  };

  const columns = [
    {
      title: '번호',
      dataIndex: 'no',
      key: 'no',
      width: 75,
      align: 'center',
    },
    {
      title: '제목',
      dataIndex: 'title',
      key: 'title',
      render: (text) => <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>{text}</div>,
    },
    {
      title: '시작일',
      dataIndex: 'startDate',
      key: 'date',
      width: 135,
      align: 'center',
    },
    {
      title: '종료일',
      dataIndex: 'endDate',
      key: 'date',
      width: 135,
      align: 'center',
    },
    {
      title: '액션',
      dataIndex: 'id',
      render: (id, record) => {
        return (
          <Button
            type="danger"
            shape="circle"
            icon={<DeleteOutlined />}
            onClick={(e) => openDeleteConfirmModal(e, record)}
          />
        );
      },
      width: 100,
      fixed: 'right',
    },
  ];

  const recommendData = camelize(data.list).map((item) => {
    const now = dayjs().format('YYYY-MM-DD');
    const isEnded = now < item.startDate || now > item.endDate;

    return {
      key: item.id,
      no: <Wrapper isEnded={isEnded}>{item.id}</Wrapper>,
      title: <Wrapper isEnded={isEnded}>{item.title}</Wrapper>,
      startDate: <Wrapper isEnded={isEnded}>{item.startDate}</Wrapper>,
      endDate: <Wrapper isEnded={isEnded}>{item.endDate}</Wrapper>,
    };
  });

  return (
    <>
      <div style={{ textAlign: 'right', marginBottom: 20 }}>
        <Button
          onClick={() => {
            history.push('/recommend-contents/write');
          }}
        >
          추천 콘텐츠 생성
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={recommendData}
        pagination={{
          current: page,
          pageSize: PAGE_SIZE,
          total: data.total,
        }}
        onChange={handleTableChange}
        onRow={handleRow}
      />
    </>
  );
}

const Wrapper = styled.div`
  color: ${({ isEnded }) => (isEnded ? '#999' : '#000')};
`;
