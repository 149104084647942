import { Button, Input, Checkbox } from 'antd';
import { useRef, useState } from 'react';
import styled from 'styled-components';

import DolbomSummerNote from './DolbomSummerNote';

export default function BoardForm({
  title: _title,
  isNotice: _isNotice = false,
  startDate: _startDate = '',
  endDate: _endDate = '',
  content,
  isEditMode = false,
  onSubmit,
}) {
  const summernoteRef = useRef(null);
  const [title, setTitle] = useState(_title);
  const [isNotice, setIsNotice] = useState(_isNotice);
  const [startDate] = useState('2000-01-01');
  const [endDate] = useState('2999-12-30');

  console.log(isNotice, _isNotice);

  const handleSubmit = () => {
    const content = summernoteRef.current.editor.summernote('code');

    if (!title?.trim()) {
      alert('제목은 필수 항목입니다.');

      return;
    }

    onSubmit &&
      onSubmit({
        title,
        content,
        isNotice,
        startDate,
        endDate,
      });
  };

  const handleNoticeCheckChange = (e) => {
    setIsNotice(e.target.checked);
  };

  // const handleDateChange = (date, dateString) => {
  //   setStartDate(dateString[0]);
  //   setEndDate(dateString[1]);
  // };

  return (
    <>
      <div style={{ marginBottom: 10, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <Checkbox checked={isNotice} onChange={handleNoticeCheckChange} style={{ marginRight: 10 }}>
          공지로 설정
        </Checkbox>
        {/* <RangePicker
          defaultValue={[startDate ? moment(startDate) : '', endDate ? moment(endDate) : '']}
          onChange={handleDateChange}
          disabled={!isNotice}
        /> */}
      </div>
      <StyledInput
        placeholder="제목을 입력해주세요."
        defaultValue={title}
        maxLength={50}
        size="large"
        onChange={(e) => setTitle(e.currentTarget.value)}
      />
      <DolbomSummerNote content={content} summernoteRef={summernoteRef} />
      <AlignCenter>
        <Button onClick={handleSubmit}>{isEditMode ? '수정하기' : '글쓰기'}</Button>
      </AlignCenter>
    </>
  );
}

const StyledInput = styled(Input)`
  margin-bottom: 20px;
`;

const AlignCenter = styled.div`
  text-align: center;
`;
