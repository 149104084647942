export const CONTENTS_INPUTS = {
  CODE: {
    label: '코드',
    name: 'code',
    type: 'text',
    placeholder: '코드를 입력해주세요',
    rules: [
      {
        required: true,
        message: '코드는 필수 입력사항입니다.',
      },
    ],
  },
  CATEGORY: {
    label: '카테고리',
    name: 'category',
    type: 'select',
    placeholder: '카테고리를 선택해주세요.',
    rules: [
      {
        required: true,
        message: '카테고리는 필수 선택사항입니다.',
      },
    ],
  },
  TITLE: {
    label: '콘텐츠 제목',
    name: 'title',
    type: 'text',
    placeholder: '콘텐츠 제목을 입력해주세요 (최대 30자)',
    maxLength: 30,
    rules: [
      {
        required: true,
        message: '콘텐츠 제목은 필수 입력사항입니다.',
      },
    ],
  },
  ACTIVITY_TIME: {
    label: '활동 시간',
    name: 'activity_time',
    type: 'number',
    placeholder: '활동 시간을 분 단위로 입력해주세요. (ex 100 = 1시간 40분)',
    rules: [
      {
        required: true,
        message: '활동 시간은 필수 입력사항입니다.',
      },
    ],
  },
  NUM_OF_QUESTION: {
    label: '퀴즈 문항 수',
    name: 'num_of_question',
    type: 'number',
    placeholder: '퀴즈 문항 수를 입력해주세요.',
    rules: [
      {
        required: true,
        message: '퀴즈 문항 수는 필수 입력사항입니다.',
      },
    ],
  },
  CONTENT: {
    label: '콘텐츠 설명',
    name: 'content',
    type: 'text',
    placeholder: '콘텐츠 설명을 입력해주세요 (최대 200자)',
    maxLength: 200,
  },
  URL: {
    label: '콘텐츠 URL',
    name: 'url',
    type: 'url',
    placeholder: '콘텐츠 URL을 입력해주세요',
    rules: [
      {
        required: true,
        message: '콘텐츠 URL은 필수 입력사항입니다.',
      },
    ],
  },
  RUNNING_TIME: {
    label: '영상 길이',
    name: 'running_time',
    type: 'number',
    placeholder: '영상 길이를 입력해주세요',
    rules: [
      {
        required: true,
        message: '영상 길이는 필수 입력사항입니다.',
      },
    ],
  },
  DIFFICULTY: {
    label: '난이도',
    name: 'difficulty',
    type: 'select',
    options: [0, 1, 2, 3],
    placeholder: '난이도를 설정해주세요.',
    rules: [
      {
        required: true,
        message: '난이도는 필수 선택사항입니다.',
      },
    ],
  },
  IMAGE_THUMBNAIL: {
    label: (
      <>
        썸네일 이미지{' '}
        <span style={{ marginLeft: 8, fontSize: '0.9em', color: '#666' }}>
          (권장 사항 - 가로:세로 비율 4:3 / 400px * 300px / jpg, jpeg)
        </span>
      </>
    ),
    name: 'image_thumbnail',
    type: 'upload',
    placeholder: '이미지를 선택해주세요',

    rules: [
      {
        required: true,
        message: '썸네일 이미지는 필수로 들어가야합니다.',
      },
    ],
  },
  SELL_MATERIAL: {
    label: '패키지 준비물',
    name: 'sell_material',
    type: 'text',
    placeholder: '패키지 준비물을 입력해주세요 (최대 100자)',
    maxLength: 100,
  },
  SELL_MATERIAL_URL: {
    label: '패키지 준비물 URL',
    name: 'sell_material_url',
    type: 'url',
    placeholder: '패키지 준비물 URL을 입력해주세요',
  },
  SELF_MATERIAL: {
    label: '개별 준비물',
    name: 'self_material',
    type: 'text',
    placeholder: '개별 준비물을 입력해주세요 (최대 100자)',
    maxLength: 100,
  },
  ACCESS_LEVEL: {
    label: '등급',
    name: 'access_level',
    type: 'select',
    options: [
      {
        value: 1,
        label: '전체',
      },
      {
        value: 2,
        label: '프리미엄 회원',
      },
    ],
    placeholder: '등급을 설정해주세요.',
    rules: [
      {
        required: true,
        message: '등급은 필수 선택사항입니다.',
      },
    ],
  },
  REFERENCE_TYPE: {
    label: '저작권 분류',
    name: 'reference_type',
    type: 'select',
    options: [
      {
        value: 1,
        label: 1,
      },
      {
        value: 2,
        label: 2,
      },
      {
        value: 3,
        label: 3,
      },
      {
        value: 4,
        label: 4,
      },
      {
        value: 5,
        label: 5,
      },
    ],
    placeholder: '저작권 분류를 선택해주세요.',
  },
  REFERENCE: {
    label: '저작권',
    name: 'reference',
    type: 'text',
    placeholder: '저작권을 입력해주세요. (최대 100자)',
    maxLength: 100,
  },
  GUIDE_DOC: {
    label: '지도서',
    name: 'guide_doc',
    type: 'url',
    placeholder: '지도서의 url을 입력해주세요',
  },
  ACTIVITY_DOC: {
    label: '활동지',
    name: 'activity_doc',
    type: 'url',
    placeholder: '활동지의 url을 입력해주세요',
  },
  DESIGN_DOC: {
    label: '도안',
    name: 'design_doc',
    type: 'url',
    placeholder: '도안의 url을 입력해주세요',
  },
  QUIZ_DOC: {
    label: '퀴즈',
    name: 'quiz_doc',
    type: 'url',
    placeholder: '퀴즈의 url을 입력해주세요',
  },
};

export const EVENT_INPUTS = {
  TITLE: {
    label: '제목',
    name: 'title',
    type: 'text',
    placeholder: '제목을 입력해주세요. (최대 100자)',
    maxLength: 100,
    rules: [
      {
        required: true,
        message: '제목은 필수 입력사항입니다.',
      },
    ],
  },
  TYPE: {
    label: '타입',
    name: 'type',
    type: 'select',
    placeholder: '타입을 선택해주세요.',
    options: [
      {
        value: 'event',
        label: '이벤트',
      },
      {
        value: 'banner',
        label: '배너(외부 링크)',
      },
    ],
    rules: [
      {
        required: true,
        message: '배너 이미지는 필수로 들어가야합니다.',
      },
    ],
  },
  DATE: {
    label: '등록 기간',
    name: 'date',
    rules: [{ type: 'array', required: true, message: '기간은 필수 입력사항입니다.' }],
    format: 'YYYY-MM-DD',
  },
  NO_END_DATE: {
    label: '종료일 없이 설정',
    name: 'noEndDate',
    rules: [{ type: 'checkbox' }],
  },
  BANNER_URL: {
    label: (
      <>
        배너 이미지{' '}
        <span style={{ marginLeft: 8, fontSize: '0.9em', color: '#666' }}>
          (사이즈: 2560 * 480) (업로드하지 않으면 배너가 노출되지 않습니다.)
        </span>
      </>
    ),
    name: 'bannerUrl',
    type: 'upload',
    placeholder: '이미지를 선택해주세요',
  },
  THUMBNAIL_URL: {
    label: (
      <>
        썸네일 이미지{' '}
        <span style={{ marginLeft: 8, fontSize: '0.9em', color: '#666' }}>
          (권장 사항 - 가로:세로 비율 2:1 / 800px * 400px / jpg, jpeg)
        </span>
      </>
    ),
    name: 'thumbnailUrl',
    type: 'upload',
    placeholder: '이미지를 선택해주세요',
    rules: [
      {
        required: true,
        message: '썸네일 이미지는 필수로 들어가야합니다.',
      },
    ],
  },
  LINK: {
    label: '연결 링크',
    name: 'link',
    type: 'text',
    placeholder: '연결할 링크를 입력해주세요.',
    rules: [
      {
        required: true,
        message: '연결 링크는 필수 입력사항입니다.',
      },
    ],
  },
};

export const RECOMMEND_INPUTS = {
  TITLE: {
    label: '제목',
    name: 'title',
    type: 'text',
    placeholder: '제목을 입력해주세요. (최대 30자)',
    maxLength: 30,
    rules: [
      {
        required: true,
        message: '제목은 필수 입력사항입니다.',
      },
    ],
  },
  DATE: {
    label: '노출 기간',
    name: 'date',
    rules: [{ type: 'array', required: true, message: '기간은 필수 입력사항입니다.' }],
    format: 'YYYY-MM-DD',
  },
};
