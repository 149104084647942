import { patchRecommend } from 'api/requests/recommendRequests';
import RecommendForm from 'components/recommend-contents/RecommendForm';
import { useHistory, useParams } from 'react-router-dom';
import useSWR from 'swr';
import { camelize } from 'utils/obj';

export default function RecommendContentsEditPage() {
  const history = useHistory();
  const { id } = useParams();
  const { data: _data, mutate } = useSWR(`/recommend/${id}`);

  if (!_data) {
    return null;
  }
  const data = camelize(_data);

  return (
    <RecommendForm
      data={data}
      isEditMode={true}
      onSubmit={(value) => {
        patchRecommend(value).then(() => {
          mutate();
          history.push('/recommend-contents');
        });
      }}
    />
  );
}
