import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Form, Input, Button, Typography, Modal } from 'antd';
import { requestLogin } from 'api';
import { useSetRecoilState } from 'recoil';
import authAtom from 'recoils/auth';
import styled from 'styled-components';

const { Title } = Typography;

function LoginPage({ history }) {
  const setAuth = useSetRecoilState(authAtom);

  const setPersistToken = (token) => {
    localStorage.setItem('__DOLBOM_ADMIN_TOKEN__', token);
  };

  const handleFinish = (values) => {
    (async () => {
      const { data, error } = await requestLogin(values);
      if (error) {
        Modal.error({
          title: `${error.response.status} 로그인에 실패하였습니다`,
          content: error.response.data.description,
        });

        return;
      }

      if (data) {
        setAuth(data);
        setPersistToken(data.token);
        history.push('/contents/video');

        return;
      }
    })();
  };

  return (
    <CenterLayout>
      <Title level={2}>돌봄 관리자</Title>
      <Box>
        <Form layout="vertical" onFinish={handleFinish}>
          <Form.Item label="이메일" name="email" rules={[{ required: true, message: '이메일은 필수 입력사항입니다.' }]}>
            <Input prefix={<UserOutlined />} placeholder="이메일 입력" />
          </Form.Item>
          <Form.Item
            label="비밀번호"
            name="password"
            rules={[{ required: true, message: '비밀번호는 필수 입력사항입니다.' }]}
          >
            <Input prefix={<LockOutlined />} type="password" placeholder="비밀번호 입력" />
          </Form.Item>

          <LoginButtonFormItem>
            <Button block type="primary" htmlType="submit">
              로그인
            </Button>
          </LoginButtonFormItem>
        </Form>
      </Box>
    </CenterLayout>
  );
}

const CenterLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1 1;
  background: #f7f7f7;
`;

const Box = styled.div`
  background-color: #fff;
  border: 1px solid #e9e9e9;
  padding: 32px;
  border-radius: 8px;

  form {
    width: 300px;
  }
`;

const LoginButtonFormItem = styled(Form.Item)`
  padding-top: 12px;
`;

export default LoginPage;
