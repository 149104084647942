import { UploadOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, message, Modal, Row, Upload } from 'antd';
import { uploadRequest } from 'api';

function EditModal({ visible, initialValues, onCancel, onEdit }) {
  const [form] = Form.useForm();
  console.log(initialValues);

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      okText="수정"
      cancelText="취소"
      onOk={() => {
        form
          .validateFields()
          .then(async (values) => {
            const success = await onEdit(values);
            console.log(success);
            if (success) {
              message.success('카테고리 수정이 완료되었습니다.');
              form.resetFields();
            }
          })
          .catch((info) => {
            console.error('Validate Failed:', info);
          });
      }}
    >
      <Form
        form={form}
        initialValues={initialValues}
        layout="vertical"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <Row gutter={16} style={{ paddingBottom: 24 }}>
          <Col span={24}>
            <Form.Item
              label="카테고리명"
              name="name"
              rules={[
                {
                  required: true,
                  message: '카테고리명을 입력해주세요.',
                },
              ]}
            >
              <Input placeholder="추가할 카테고리명을 입력해주세요." />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              label={
                <>
                  카테고리 썸네일{' '}
                  <span style={{ marginLeft: 8, fontSize: '0.9em', color: '#666' }}>
                    (권장 사항 - 1:1 / 400px * 400px / jpg, jpeg)
                  </span>
                </>
              }
              name="thumbnail_url"
              rules={[
                {
                  required: true,
                  message: '카테고리 썸네일을 업로드해주세요.',
                },
              ]}
            >
              <Upload
                action={uploadRequest}
                headers={{
                  authorization: `Bearer ${(window.localStorage.getItem('__DOLBOM_ADMIN_TOKEN__') || null).replace(
                    /"/g,
                    ''
                  )}`,
                }}
                maxCount={1}
                accept="image/*"
                listType="picture"
                defaultFileList={
                  initialValues?.thumbnail_url?.length > 0
                    ? [
                        {
                          name: initialValues?.thumbnail_url,
                          url: initialValues?.thumbnail_url,
                          thumbUrl: initialValues?.thumbnail_url,
                          uid: '-1',
                          status: 'done',
                        },
                      ]
                    : []
                }
              >
                <Button icon={<UploadOutlined />}>
                  {initialValues?.thumbnail_url?.length > 0 ? '수정하기' : '업로드'}
                </Button>
              </Upload>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default EditModal;
