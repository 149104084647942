import { Form, Input, Modal } from 'antd';

function UserPwResetModal({ visible, onResetFinish, onCancel }) {
  const [form] = Form.useForm();
  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      okText="비밀번호 초기화"
      cancelText="취소"
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onResetFinish(values);
            form.resetFields();
          })
          .catch((info) => {
            console.error('Validate Failed:', info);
          });
      }}
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <Form form={form} style={{ padding: 20 }} layout="vertical">
        <Form.Item
          style={{ width: '100%' }}
          label="초기화할 비밀번호"
          name="password"
          required
          rules={[
            {
              min: 8,
              message: '8자 이상 입력해주세요.',
            },
          ]}
        >
          <Input type="password" placeholder="초기화할 비밀번호를 입력해주세요." />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default UserPwResetModal;
