export const YEARS = [
  {
    label: '2021년',
    value: '2021',
  },
  {
    label: '2022년',
    value: '2022',
  },
  {
    label: '2023년',
    value: '2023',
  },
  {
    label: '2024년',
    value: '2024',
  },
  {
    label: '2025년',
    value: '2025',
  },
  {
    label: '2026년',
    value: '2026',
  },
];

// dayjs month() 가 1월이 0부터 시작
export const MONTHS = [
  {
    label: '1월',
    value: 0,
  },
  {
    label: '2월',
    value: 1,
  },
  {
    label: '3월',
    value: 2,
  },
  {
    label: '4월',
    value: 3,
  },
  {
    label: '5월',
    value: 4,
  },
  {
    label: '6월',
    value: 5,
  },
  {
    label: '7월',
    value: 6,
  },
  {
    label: '8월',
    value: 7,
  },
  {
    label: '9월',
    value: 8,
  },
  {
    label: '10월',
    value: 9,
  },
  {
    label: '11월',
    value: 10,
  },
  {
    label: '12월',
    value: 11,
  },
];
