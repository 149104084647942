import { Typography, Button, Checkbox } from 'antd';
import { deletePost, patchPost } from 'api/requests/boardRequests';
import CommentSection from 'components/board/CommentSection';
import PostAuthor from 'components/board/PostAuthor';
import { CommentsProvider } from 'context/CommentsContextProvider';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import useSWR from 'swr';
import { camelize } from 'utils/obj';

const { Title } = Typography;

export default function BoardDetailPage() {
  const history = useHistory();
  const { id } = useParams();
  const { data: _data } = useSWR(`/dolbom-board/${id}`);
  const [isNotice, setIsNotice] = useState(false);
  const [diabledCheckBox, setDisabledCheckBox] = useState(false);

  useEffect(() => {
    if (_data?.is_notice) {
      setIsNotice(_data.is_notice);
    }
  }, [_data]);

  if (!_data) {
    return null;
  }
  const data = camelize(_data);

  const handleEditButtonClick = () => {
    history.push(`/board/edit/${id}`);
  };

  const handleNoticeCheckChange = (e) => {
    setDisabledCheckBox(true);
    patchPost({
      id,
      title: data.title,
      content: data.content,
      startDate: data.startDate || '2000-01-01',
      endDate: data.endDate || '2999-12-30',
      isNotice: e.target.checked,
    }).then(() => {
      setIsNotice(e.target.checked);
      setDisabledCheckBox(false);
    });
  };

  const handleDeleteButtonClick = () => {
    deletePost({ id });

    history.push('/board');
  };

  return (
    <>
      <div style={{ marginBottom: 10, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <Checkbox checked={isNotice} onChange={handleNoticeCheckChange} disabled={diabledCheckBox}>
          공지로 설정
        </Checkbox>
      </div>
      <Title level={2}>{data.title}</Title>
      <PostAuthor
        author={data.accountId}
        createdAt={data.createdAt}
        viewCount={data.viewCount}
        commentCount={data.comment.length}
        isWriter={data.isWriter}
        isAdminDeleted={data.isAdminDeleted}
        onEditButtonClick={handleEditButtonClick}
        onDeleteButtonClick={handleDeleteButtonClick}
      />
      <StyledContent className={'bootstrap-style'}>
        <div dangerouslySetInnerHTML={{ __html: data?.content }} />
      </StyledContent>
      <CommentsProvider>
        <CommentSection boardId={id || ''} comments={data?.comment || []} />
      </CommentsProvider>
      <BottomButtonGroup>
        <Button
          onClick={() => {
            history.push('/board');
          }}
        >
          목록으로
        </Button>
      </BottomButtonGroup>
    </>
  );
}

const StyledContent = styled.div`
  padding: 35px 0;
  min-height: 400px;
  border-top: 1px solid #e9e9e9;
  font-size: 1.1rem;
  margin-bottom: 20px;
`;

const BottomButtonGroup = styled.div`
  margin-top: 15px;
  text-align: left;
`;
