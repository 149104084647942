import { Button, Input, Form, Rate, Col, Row, DatePicker, Table } from 'antd';
import { formatTimeStr } from 'antd/lib/statistic/utils';
import Thumbnail from 'components/common/Thumbnail';
import { RECOMMEND_INPUTS } from 'constants/inputs';
import moment from 'moment';
import { useState } from 'react';
import styled from 'styled-components';

import RecommendContentsListModal from './RecommendContentsListModal';

const { RangePicker } = DatePicker;

export const columns = [
  {
    title: '코드',
    dataIndex: 'code',
    key: 'code',
    fixed: 'left',
    width: 100,
    align: 'center',
  },
  {
    title: '썸네일',
    dataIndex: 'imageThumbnail',
    key: 'imageThumbnail',
    fixed: 'left',
    width: 80,
    render: (imageThumbnail) => <Thumbnail src={imageThumbnail} />,
    align: 'center',
  },
  {
    title: '카테고리',
    dataIndex: 'category',
    key: 'category',
    width: 120,
    align: 'center',
  },
  {
    title: '제목',
    dataIndex: 'title',
    key: 'title',
    width: 300,
  },
  {
    title: '영상길이',
    dataIndex: 'runningTime',
    key: 'runningTime',
    width: 90,
    render: (runningTime) => {
      return formatTimeStr(runningTime * 1000, 'mm:ss');
    },
    align: 'center',
  },
  {
    title: '난이도',
    dataIndex: 'difficulty',
    key: 'difficulty',
    render: (difficulty) => <Rate defaultValue={difficulty} count={3} disabled />,
    width: 120,
    align: 'center',
  },
  {
    title: '좋아요',
    dataIndex: 'likeCount',
    key: 'likeCount',
    width: 80,
    align: 'center',
  },
  {
    title: '조회수',
    dataIndex: 'viewCount',
    key: 'viewCount',
    width: 80,
    align: 'center',
  },
  {
    title: '등급',
    dataIndex: 'accessLevel',
    key: 'accessLevel',
    render: (accessLevel) => (accessLevel === 1 ? '전체' : '프리미엄'),
    width: 100,
    align: 'center',
  },
  {
    title: '준비물',
    dataIndex: 'sellMaterial',
    key: 'sellMaterial',
    render: (d) => (d ? '🟢' : ''),
    width: 70,
    align: 'center',
  },
  {
    title: '활동지',
    dataIndex: 'activityDoc',
    key: 'activityDoc',
    render: (d) => (d ? '🟢' : ''),
    width: 70,
    align: 'center',
  },
  {
    title: '지도서',
    dataIndex: 'guideDoc',
    key: 'guideDoc',
    render: (d) => (d ? '🟢' : ''),
    width: 70,
    align: 'center',
  },
  {
    title: '도안',
    dataIndex: 'designDoc',
    key: 'designDoc',
    render: (d) => (d ? '🟢' : ''),
    width: 70,
    align: 'center',
  },
  {
    title: '퀴즈',
    dataIndex: 'quizDoc',
    key: 'quizDoc',
    render: (d) => (d ? '🟢' : ''),
    width: 70,
    align: 'center',
  },
];

export default function RecommendForm({
  data = { id: '', title: '', startDate: '', endDate: '', contents: [] },
  isEditMode = false,
  onSubmit,
}) {
  const [form] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);
  const [contentsList, setContentsList] = useState(data.contents);

  const handleFinish = (fieldsValue) => {
    onSubmit &&
      onSubmit({
        ...(isEditMode && { id: data.id }),
        title: fieldsValue.title,
        startDate: fieldsValue.date[0].format('YYYY-MM-DD'),
        endDate: fieldsValue.date[1].format('YYYY-MM-DD'),
        contentsList: contentsList.map((item) => item.id),
      });
  };

  const initialValues = {
    title: data.title,
    date: [
      data.startDate ? moment(data.startDate, 'YYYY-MM-DD') : null,
      data.endDate ? moment(data.endDate, 'YYYY-MM-DD') : null,
    ],
  };

  const recommendContentsData = contentsList.map((item) => ({
    key: item.id,
    ...item,
    category: item.category4Name || item.category2Name,
  }));

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleFinish}
        initialValues={initialValues}
        onSubmit={(e) => {
          console.log(e);
          e.preventDefault();
        }}
      >
        <Row gutter={32}>
          <Col span={12}>
            <Form.Item
              label={RECOMMEND_INPUTS.TITLE.label}
              name={RECOMMEND_INPUTS.TITLE.name}
              rules={RECOMMEND_INPUTS.TITLE.rules}
            >
              <Input
                type={RECOMMEND_INPUTS.TITLE.type}
                placeholder={RECOMMEND_INPUTS.TITLE.placeholder}
                maxLength={RECOMMEND_INPUTS.TITLE.maxLength}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={RECOMMEND_INPUTS.DATE.label}
              name={RECOMMEND_INPUTS.DATE.name}
              rules={RECOMMEND_INPUTS.DATE.rules}
            >
              <RangePicker format={RECOMMEND_INPUTS.DATE.format} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={24}>
            <div style={{ textAlign: 'right', marginBottom: 15 }}>
              <Button onClick={() => setModalVisible(true)}>콘텐츠 편집</Button>
            </div>
            <Table
              columns={columns}
              dataSource={recommendContentsData}
              pagination={{ position: ['none'] }}
              scroll={{ x: 2000 }}
            />
          </Col>
        </Row>

        <AlignCenter>
          <Button htmlType="submit">{isEditMode ? '수정하기' : '등록하기'}</Button>
        </AlignCenter>
      </Form>
      {modalVisible && (
        <RecommendContentsListModal
          visible={modalVisible}
          onCancel={(newContentsList) => {
            setContentsList(newContentsList);
            setModalVisible(false);
          }}
          defaultContentsList={contentsList}
          maxContents={20}
        />
      )}
    </>
  );
}

const AlignCenter = styled.div`
  margin-top: 20px;
  text-align: center;
`;
