import { deleteComment } from 'api/requests/boardRequests';
import dayjs from 'dayjs';
import { useCallback, useState } from 'react';
import styled from 'styled-components';

import CommentForm from './CommentForm';

export default function Comment({
  id,
  index,
  content,
  isDeleted,
  isAdminDeleted,
  accountId,
  createdAt,
  comment: replyList = [],
  boardId,
  parentIndex,
  className,
}) {
  const [showReplyForm] = useState(false);
  const [showCommentForm, setShowCommentForm] = useState(false);

  // const handleEditButtonClick = useCallback(() => {
  //   setShowCommentForm(true);
  // }, []);

  const handleDeleteButtonClick = useCallback(() => {
    (async () => {
      const { error } = await deleteComment({ id });

      if (error) {
        console.error(error.response);
        alert('댓글 삭제에 실패하였습니다.');
        return;
      }

      window.location.reload();
    })();
  }, [id]);

  const today = new Date();

  return (
    <>
      <CommentWrapper className={className}>
        {isAdminDeleted ? (
          <DeletedContent>관리자에 의해 삭제된 댓글입니다.</DeletedContent>
        ) : isDeleted ? (
          <DeletedContent>삭제된 댓글입니다.</DeletedContent>
        ) : showCommentForm ? (
          <CommentForm
            key={new Date()}
            editMode={true}
            id={id}
            parentIndex={parentIndex}
            index={index}
            boardId={boardId}
            onClose={() => {
              setShowCommentForm(false);
            }}
            defaultContent={content}
          />
        ) : (
          <>
            <Author>{accountId}</Author>
            <Content>{content}</Content>
            <>
              <More>
                <CreatedAt>
                  {dayjs(createdAt).format('YYYYMMDD') === dayjs(today).format('YYYYMMDD')
                    ? dayjs(createdAt).format('hh:mm')
                    : dayjs(createdAt).format('YYYY-MM-DD')}
                </CreatedAt>
                {/* {boardId && <ReplyButton onClick={() => setShowReplyForm(true)}>대댓글</ReplyButton>} */}
                {/* <div onClick={handleEditButtonClick}>수정</div> */}
                <div onClick={handleDeleteButtonClick} style={{ cursor: 'pointer' }}>
                  삭제
                </div>
              </More>
            </>
          </>
        )}
      </CommentWrapper>

      {(showReplyForm || replyList?.length > 0) && (
        <ReplySection>
          {replyList.map((reply, replyIndex) => (
            <Reply key={'reply' + reply.id} {...reply} index={replyIndex} parentIndex={index} />
          ))}
          {/* {showReplyForm && <ReplyForm parentId={id} boardId={boardId} />} */}
        </ReplySection>
      )}
    </>
  );
}

const CommentWrapper = styled.div`
  padding: 18px;
  border-top: 1px solid #e9e9e9;
`;

const Author = styled.div`
  color: #aaaaaa;
  margin-bottom: 15px;
  font-size: 0.9rem;
`;

const Content = styled.div`
  font-size: 1.05rem;
  margin-bottom: 12px;
  word-break: break-all;
`;

const More = styled.div`
  font-size: 0.8rem;
  color: #bbb;

  & div {
    display: inline-block;
    margin-right: 10px;
  }
`;

const CreatedAt = styled.div``;

// const ReplyButton = styled.div`
//   cursor: pointer;
// `;

const ReplySection = styled.div`
  background: #fafafa;
  padding: 0 0 25px 25px;
`;

const Reply = styled(Comment)`
  padding: 18px 18px 0;
  margin-top: 18px;
  border-top: 1px solid #e9e9e9;

  &:first-child {
    margin-top: 0;
  }
`;
const DeletedContent = styled(Content)`
  color: #999;
  margin-top: 12px;
`;

// const ReplyForm = styled(CommentForm)`
//   position: relative;
//   margin: 0 20px 40px;
//   padding-top: 20px;
// `;
