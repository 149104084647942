import api from 'api';
import * as R from 'ramda';

export const requestEditUser = async (params) => {
  const { data, error } = await api({
    method: 'patch',
    url: `/account/${params?.originId}`,
    data: { ...R.omit(['originId'], params) },
  });

  return {
    data,
    error,
    requestName: 'USER_EDIT',
  };
};

export const requestUserResetPw = async (params) => {
  const { data, error } = await api({
    method: 'patch',
    url: `/account/${params?.id}/password`,
    data: { ...R.omit(['id'], params) },
  });

  return {
    data,
    error,
    requestName: 'USER_RESET_PW',
  };
};
