import {
  CommentOutlined,
  TagsOutlined,
  AppstoreOutlined,
  FolderOpenOutlined,
  TeamOutlined,
  ScheduleOutlined,
  EyeOutlined,
  QuestionCircleOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import { routePaths } from 'constants/pathMap';
import { memo, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const { Sider } = Layout;

function Nav() {
  const [openKeys, setOpenKeys] = useState([]);
  const location = useLocation();

  const pathname = location.pathname;

  const selectedKey = useMemo(() => {
    return pathname;
  }, [pathname]);

  const selectedOpenKey = useMemo(() => {
    if (pathname.includes(routePaths.CONTENTS)) {
      setOpenKeys([routePaths.CONTENTS]);
      return routePaths.CONTENTS;
    }
  }, [pathname]);

  const handleMenuOpenChange = (openKeys) => {
    setOpenKeys(openKeys);
  };

  return (
    <Sider breakpoint="lg" collapsedWidth="0" onBreakpoint={(broken) => {}} onCollapse={(collapsed, type) => {}}>
      <div className="logo" />
      <Menu
        theme="dark"
        mode="inline"
        onClick={(e) => {}}
        defaultSelectedKeys={selectedKey}
        defaultOpenKeys={selectedOpenKey}
        openKeys={openKeys}
        onOpenChange={handleMenuOpenChange}
      >
        <Menu.SubMenu key={routePaths.CONTENTS} title="콘텐츠 관리" icon={<AppstoreOutlined />}>
          <Menu.Item key={routePaths.CONTENTS_VIDEO} icon={<VideoCameraOutlined />}>
            <Link to={routePaths.CONTENTS_VIDEO}>영상 콘텐츠 관리</Link>
          </Menu.Item>
          <Menu.Item key={routePaths.CONTENTS_QUIZ} icon={<QuestionCircleOutlined />}>
            <Link to={routePaths.CONTENTS_QUIZ}>퀴즈 콘텐츠 관리</Link>
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.Item key="/category" icon={<FolderOpenOutlined />}>
          <Link to={routePaths.CATEGORY}>카테고리 관리</Link>
        </Menu.Item>
        <Menu.Item key="/banner-event" icon={<TagsOutlined />}>
          <Link to={routePaths.BANNER_EVENT}>배너 및 이벤트 관리</Link>
        </Menu.Item>
        <Menu.Item key="/board" icon={<CommentOutlined />}>
          <Link to={routePaths.BOARD}>게시판 관리</Link>
        </Menu.Item>
        <Menu.Item key="/monthly-contents" icon={<ScheduleOutlined />}>
          <Link to={routePaths.MONTHLY_CONTENTS}>월간 콘텐츠 관리</Link>
        </Menu.Item>
        <Menu.Item key="/recommend-contents" icon={<EyeOutlined />}>
          <Link to={routePaths.RECOMMEND_CONTENTS}>추천 콘텐츠 관리</Link>
        </Menu.Item>

        <Menu.Item key="/user" icon={<TeamOutlined />}>
          <Link to={routePaths.USER}>유저 관리</Link>
        </Menu.Item>
      </Menu>
    </Sider>
  );
}

export default memo(Nav);
