import api from 'api';

export const requestCreateCategory = async ({ parentId, ...rest }) => {
  const { data, error } = await api({
    method: 'post',
    url: `/category/add/${parentId}`,
    data: rest,
  });

  return {
    data,
    error,
    requestName: 'CREATE_CATEGORY',
  };
};

export const requestEditCategory = async ({ id, ...rest }) => {
  const { data, error } = await api({
    method: 'patch',
    url: `/category/${id}`,
    data: rest,
  });

  return {
    data,
    error,
    requestName: 'EDIT_CATEGORY',
  };
};

export const requestDeleteCategory = async ({ id }) => {
  const { data, error } = await api({
    method: 'delete',
    url: `/category/${id}`,
  });

  return {
    data,
    error,
    requestName: 'DELETE_CATEGORY',
  };
};
export const requestReOrderCategory = async ({ parentId, list }) => {
  const { data, error } = await api({
    method: 'patch',
    url: `/category/order/${parentId}`,
    data: {
      list,
    },
  });

  return {
    data,
    error,
    requestName: 'REORDER_CATEGORY',
  };
};
