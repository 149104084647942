import { Button, Col, Form, Input, message, Radio, Row } from 'antd';
import { requestEditUser, requestUserResetPw } from 'api/requests/userRequests';
import UserPwResetModal from 'components/user/UserPwResetModal';
import * as R from 'ramda';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router';
import styled from 'styled-components';
import useSWR from 'swr';

function UserDetailPage() {
  const params = useParams();
  const history = useHistory();
  const [resetModalVisible, setResetModalVisible] = useState(false);

  const [form] = Form.useForm();
  const { data, mutate } = useSWR(`/account/${params?.id}`);

  const handlePwResetClick = () => {
    setResetModalVisible(true);
  };

  const handleFinish = (values) => {
    (async () => {
      const { error } = await requestEditUser({
        originId: params?.id,
        recommended_by_name: '',
        recommended_by_school: '',
        ...R.omit(['email', 'id'], values),
      });

      if (error) {
        message.error(error?.response?.data?.description ?? '유저 정보 수정에 실패하였습니다.');
        return;
      }

      message.success('성공적으로 수정하였습니다.');
      mutate();
      history.push('/user');
    })();
  };

  const onResetFinish = (values) => {
    (async () => {
      const { error } = await requestUserResetPw({
        id: data?.id,
        password: values?.password,
      });

      if (error) {
        message.error(error?.response?.data?.description ?? '유저 비밀번호를 초기화에 실패하였습니다.');
        return;
      }

      setResetModalVisible(false);
      message.success('유저 비밀번호를 초기화하였습니다.');
    })();
  };
  return data ? (
    <>
      <Box>
        <Form form={form} layout="vertical" onFinish={handleFinish} initialValues={data}>
          <Row gutter={16}>
            <Form.Item style={{ width: '100%' }} label="아이디" name="id">
              <Input disabled placeholder="아이디를 입력해주세요" />
            </Form.Item>
            <Row style={{ padding: '20px 0' }}>
              <Button danger type="button" onClick={handlePwResetClick}>
                회원 비밀번호 초기화
              </Button>
            </Row>
            <Form.Item style={{ width: '100%' }} label="이메일" name="email">
              <Input disabled placeholder="이메일을 입력해주세요" />
            </Form.Item>
            <Form.Item style={{ width: '100%' }} label="추천인 아이디" name="recommended_by_id">
              <Input disabled />
            </Form.Item>
            <Form.Item
              style={{ width: '100%' }}
              label="이름"
              name="name"
              rules={[{ required: true, message: '이름은 필수 입력사항입니다.' }]}
            >
              <Input placeholder="이름을 입력해주세요" />
            </Form.Item>
            <Form.Item
              style={{ width: '100%' }}
              label="핸드폰번호"
              name="hp"
              rules={[{ required: true, message: '핸드폰번호는 필수 입력사항입니다.' }]}
            >
              <Input placeholder="핸드폰번호를 입력해주세요" />
            </Form.Item>
            <Form.Item
              style={{ width: '100%' }}
              label="학교명"
              name="school_name"
              rules={[{ required: true, message: '학교명은 필수 입력사항입니다.' }]}
            >
              <Input placeholder="학교명을 입력해주세요" />
            </Form.Item>
            <Form.Item style={{ width: '100%' }} label="반" name="school_class_name">
              <Input placeholder="반을 입력해주세요" />
            </Form.Item>
            <Form.Item
              style={{ width: '100%' }}
              label="메일링"
              name="mailling"
              rules={[{ required: true, message: '메일링은 필수 입력사항입니다.' }]}
            >
              <Radio.Group>
                <Radio value={true}>메일링 받기</Radio>
                <Radio value={false}>메일링 취소</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              style={{ width: '100%' }}
              label="SMS"
              name="sms"
              rules={[{ required: true, message: 'SMS은 필수 입력사항입니다.' }]}
            >
              <Radio.Group>
                <Radio value={true}>SMS 받기</Radio>
                <Radio value={false}>SMS 취소</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              style={{ width: '100%' }}
              label="레벨"
              name="level"
              rules={[{ required: true, message: '레벨은 필수 입력사항입니다.' }]}
            >
              <Radio.Group>
                <Radio value={2}>일반</Radio>
                <Radio value={5}>프리미엄</Radio>
                <Radio value={10}>어드민</Radio>
              </Radio.Group>
            </Form.Item>
          </Row>

          <Row style={{ paddingTop: 32 }} gutter={16} justify="center">
            <Col>
              <Button
                size="large"
                block
                htmlType="button"
                onClick={() => {
                  history.goBack();
                }}
              >
                취소
              </Button>
            </Col>
            <Col>
              <Button size="large" block type="primary" htmlType="button" onClick={form.submit}>
                수정
              </Button>
            </Col>
          </Row>
        </Form>
      </Box>
      {resetModalVisible && (
        <UserPwResetModal
          visible={resetModalVisible}
          onCancel={() => {
            setResetModalVisible(false);
          }}
          onResetFinish={onResetFinish}
        />
      )}
    </>
  ) : null;
}

const Box = styled.div`
  background-color: #fff;
  margin: 0 auto;
  border: 1px solid #e9e9e9;
  padding: 32px;
  border-radius: 8px;
  max-width: 960px;
`;

export default UserDetailPage;
