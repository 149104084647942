import { DeleteOutlined } from '@ant-design/icons';
import { Table, Button, Modal, message } from 'antd';
import { deleteEvent as reqeustDeleteEvent } from 'api/requests/eventRequests';
import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useSWR from 'swr';
import { camelize } from 'utils/obj';

const PAGE_SIZE = 15;

export default function BannerEventPage() {
  const history = useHistory();
  const [page, setPage] = useState(1);
  const { data, mutate } = useSWR(`/event?offset=${(page - 1) * PAGE_SIZE}&limit=${PAGE_SIZE}`);

  const handleTableChange = useCallback(
    (pagination, filters, sorter) => {
      if (page !== pagination.current) {
        setPage(pagination.current);
      }
    },
    [page]
  );

  if (!data) {
    return null;
  }

  const eventData = camelize(data.list).map((item) => ({
    key: item.id,
    no: item.id,
    type: item.thumbnailUrl ? '이벤트' : '배너(링크)',
    bannerUrl: <img src={item.bannerUrl} width={'100%'} alt="" />,
    title: item.title,
    startDate: item.startDate,
    endDate: item.endDate,
  }));

  const deleteEvent = ({ id }) => {
    (async () => {
      const { error } = await reqeustDeleteEvent({ id });

      if (error) {
        console.log(error);
        Modal.error({
          title: error.response.data.description || '이벤트 삭제에 실패하였습니다.',
        });
        return;
      }

      message.success('이벤트가 삭제되었습니다.');
      mutate();
    })();
  };

  const openDeleteConfirmModal = (e, record) => {
    console.log(record);
    e.stopPropagation();
    Modal.confirm({
      title: '정말로 삭제하시겠습니까?',
      content: `"${record.title}" 이벤트가 삭제됩니다.`,
      okText: '삭제하기',
      cancelText: '취소하기',
      okButtonProps: {
        type: 'danger',
      },
      onOk: () => deleteEvent({ id: record.key }),
    });
  };

  const columns = [
    {
      title: '번호',
      dataIndex: 'no',
      key: 'no',
      width: 75,
      align: 'center',
    },
    {
      title: '제목',
      dataIndex: 'title',
      key: 'title',
      render: (text) => <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>{text}</div>,
    },
    {
      title: '시작일',
      dataIndex: 'startDate',
      key: 'date',
      width: 135,
      align: 'center',
    },
    {
      title: '종료일',
      dataIndex: 'endDate',
      key: 'date',
      render: (date) => (date > '9999-01-01' ? '' : date),
      width: 135,
      align: 'center',
    },
    {
      title: '액션',
      dataIndex: 'id',
      render: (id, record) => {
        return (
          <Button
            type="danger"
            shape="circle"
            icon={<DeleteOutlined />}
            onClick={(e) => openDeleteConfirmModal(e, record)}
          />
        );
      },
      width: 100,
      fixed: 'right',
    },
  ];

  const handleRow = (record, rowIndex) => {
    return {
      onClick: (event) => {
        history.push(`/banner-event/${record.no}`);
      },
    };
  };

  return (
    <>
      <div style={{ textAlign: 'right', marginBottom: 20 }}>
        <Button
          onClick={() => {
            history.push('/banner-event/write');
          }}
        >
          배너 및 이벤트 생성
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={eventData}
        pagination={{
          current: page,
          pageSize: PAGE_SIZE,
          total: data.total,
        }}
        onChange={handleTableChange}
        onRow={handleRow}
      />
    </>
  );
}
