import api from 'api';
import { snakize } from 'utils/obj';

export const deletePost = async ({ id }) => {
  const { data, error } = await api({
    method: 'delete',
    url: `/dolbom-board/${id}`,
  });

  return {
    data,
    error,
    requestName: 'DELETE_POST',
  };
};

export const postPost = async (params) => {
  const { data, error } = await api({
    method: 'post',
    url: '/dolbom-board',
    data: snakize(params),
  });

  return {
    data,
    error,
    requestName: 'POST_POST',
  };
};

export const patchPost = async ({ id, ...params }) => {
  const { data, error } = await api({
    method: 'patch',
    url: `/dolbom-board/${id}`,
    data: snakize(params),
  });

  return {
    data,
    error,
    requestName: 'PATCH_POST',
  };
};

export const deleteComment = async ({ id }) => {
  const { data, error } = await api({
    method: 'delete',
    url: `/dolbom-board/comment/${id}`,
  });

  return {
    data,
    error,
    requestName: 'DELETE_COMMENT',
  };
};

export const postComment = async ({ boardId, ...params }) => {
  const { data, error } = await api({
    method: 'post',
    url: `/dolbom-board/${boardId}/comment`,
    data: snakize(params),
  });

  return {
    data,
    error,
    requestName: 'POST_COMMENT',
  };
};

// export const postComment = async ({ boardId, ...params }) => {
//   const { data, error } = await api({
//     method: 'post',
//     url: `/dolbom-board/${boardId}/comment`,
//     data: snakize(params),
//   });

//   return {
//     data,
//     error,
//     requestName: 'POST_COMMENT',
//   };
// };
