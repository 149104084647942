import { Redirect } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import authAtom from 'recoils/auth';

function LandingPage() {
  const auth = useRecoilValue(authAtom);

  if (auth.token) {
    return <Redirect to="/contents/video" />;
  }
  return <Redirect to="/login" />;
}

export default LandingPage;
