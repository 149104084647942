import { DeleteOutlined } from '@ant-design/icons';
import { Button, Table, Modal } from 'antd';
import { useMemo } from 'react';
import { camelize } from 'utils/obj';

import { columns } from './columns';

function RecieveTable({ contentsList, onDeleteContents, isDaily, maxContents }) {
  const openDeleteConfirmModal = (e, record) => {
    e.stopPropagation();
    Modal.confirm({
      title: '정말로 삭제하시겠습니까?',
      content: `${record.title} 콘텐츠가 삭제됩니다.`,
      okText: '삭제하기',
      cancelText: '취소하기',
      okButtonProps: {
        type: 'danger',
      },
      onOk: () => onDeleteContents({ id: isDaily ? record.dailyScheduleId : record.weeklyScheduleId }),
    });
  };
  const receiveTableColumn = [
    ...columns,
    {
      title: '액션',
      dataIndex: 'id',
      fixed: 'right',
      render: (id, record) => {
        return (
          <Button
            type="danger"
            shape="circle"
            icon={<DeleteOutlined />}
            onClick={(e) => openDeleteConfirmModal(e, record)}
          />
        );
      },
    },
  ];

  const dataSource = useMemo(() => (contentsList ? camelize(contentsList) : []), [contentsList]);

  return (
    <>
      <Table
        columns={receiveTableColumn}
        dataSource={dataSource?.map((contents) => ({
          ...contents,
          dailyScheduleId: contents?.daily_schedule_id || contents?.dailyScheduleId,
          weeklyScheduleId: contents?.weekly_schedule_id || contents?.weeklyScheduleId,
          imageThumbnail: contents.thumbnail_url || contents.imageThumbnail,
        }))}
        rowKey="id"
        pagination={false}
        scroll={{ x: 1500 }}
      />
      <div style={{ textAlign: 'right', paddingRight: 5, marginTop: 5, fontWeight: 500 }}>
        ({contentsList.length}/{maxContents})
      </div>
    </>
  );
}

export default RecieveTable;
