import { Col, Row, Typography } from 'antd';
import NotFoundImage from 'assets/404.png';

function NotFoundPage() {
  return (
    <Row justify="center">
      <Col span={24} style={{ textAlign: 'center' }}>
        <Typography.Title>현재 개발 중인 페이지입니다.</Typography.Title>
      </Col>
      <Col span={24} style={{ textAlign: 'center' }}>
        <img style={{ width: 400 }} src={NotFoundImage} alt="404" />
      </Col>
    </Row>
  );
}

export default NotFoundPage;
