import config from 'api/config';
import axios from 'axios';

export const uploadRequest = `${config.host}/v1/image`;

const fileFetcher = axios.create({
  baseURL: uploadRequest,
});

export const uploadImage = async ({ image }) => {
  const formData = new FormData();
  formData.append('file', image);

  const { data, error } = await fileFetcher({
    method: 'post',
    headers: {
      'Content-type': 'multipart/form-data',
      authorization: `Bearer ${(window.localStorage.getItem('__DOLBOM_ADMIN_TOKEN__') || null).replace(/"/g, '')}`,
    },
    data: formData,
  });

  return {
    data,
    error,
    requestName: 'UPLOAD_IMAGE',
  };
};
