import { atom } from 'recoil';

const authAtom = atom({
  key: 'auth',
  default: {
    email: '',
    id: -1,
    name: '',
    token: localStorage.getItem('__DOLBOM_ADMIN_TOKEN__') || null,
  },
});

export default authAtom;
