import BaseLayoutWithRoute from 'components/layout/BaseLayoutWithRoute';
import { routePaths } from 'constants/pathMap';
import BannerEventEditPage from 'pages/BannerEventEditPage';
import BannerEventPage from 'pages/BannerEventPage';
import BannerEventWritePage from 'pages/BannerEventWritePage';
import BoardDetailPage from 'pages/BoardDetailPage';
import BoardEditPage from 'pages/BoardEditPage';
import BoardPage from 'pages/BoardPage';
import BoardWritePage from 'pages/BoardWritePage';
import CategoryPage from 'pages/CategoryPage';
import ContentsCreatePage from 'pages/ContentsCreatePage';
import ContentsDetailPage from 'pages/ContentsDetailPage';
import LandingPage from 'pages/LandingPage';
import LoginPage from 'pages/LoginPage';
import LogoutPage from 'pages/LogoutPage';
import MonthlyContentsPage from 'pages/MonthlyContentsPage';
import NotFoundPage from 'pages/NotFoundPage';
import QuizContentsPage from 'pages/QuizContentsPage';
import RecommendContentsEditPage from 'pages/RecommendContentsEditPage';
import RecommendContentsPage from 'pages/RecommendContentsPage';
import RecommendContentsWritePage from 'pages/RecommendContentsWritePage';
import UserDetailPage from 'pages/UserDetailPage';
import UserListPage from 'pages/UserListPage';
import VideoContentsPage from 'pages/VideoContentsPage';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

function Routes() {
  return (
    <Router>
      <Switch>
        <Route exact path={routePaths.HOME} component={LandingPage} />
        <BaseLayoutWithRoute
          title="영상 콘텐츠 리스트"
          exact
          path={routePaths.CONTENTS_VIDEO}
          component={VideoContentsPage}
        />
        <BaseLayoutWithRoute
          title="영상 콘텐츠 생성하기"
          exact
          path={routePaths.CONTENTS_VIDEO_CREATE}
          component={ContentsCreatePage}
        />
        <BaseLayoutWithRoute
          title="영상 콘텐츠 관리"
          exact
          path={`${routePaths.CONTENTS_VIDEO}/:id`}
          component={ContentsDetailPage}
        />
        <BaseLayoutWithRoute
          title="퀴즈 콘텐츠 리스트"
          exact
          path={routePaths.CONTENTS_QUIZ}
          component={QuizContentsPage}
        />
        <BaseLayoutWithRoute
          title="퀴즈 콘텐츠 생성하기"
          exact
          path={routePaths.CONTENTS_QUIZ_CREATE}
          component={ContentsCreatePage}
        />
        <BaseLayoutWithRoute
          title="퀴즈 콘텐츠 관리"
          exact
          path={`${routePaths.CONTENTS_QUIZ}/:id`}
          component={ContentsDetailPage}
        />
        <BaseLayoutWithRoute title="카테고리 관리" exact path={routePaths.CATEGORY} component={CategoryPage} />
        <BaseLayoutWithRoute
          title="월간 콘텐츠관리"
          exact
          path={routePaths.MONTHLY_CONTENTS}
          component={MonthlyContentsPage}
        />
        <BaseLayoutWithRoute title="게시판관리" exact path={routePaths.BOARD} component={BoardPage} />
        <BaseLayoutWithRoute title="글 쓰기" exact path={`${routePaths.BOARD}/write`} component={BoardWritePage} />
        <BaseLayoutWithRoute title="게시판관리" exact path={`${routePaths.BOARD}/:id`} component={BoardDetailPage} />
        <BaseLayoutWithRoute title="글 수정" exact path={`${routePaths.BOARD}/edit/:id`} component={BoardEditPage} />
        <BaseLayoutWithRoute
          title="배너 및 이벤트 관리"
          exact
          path={`${routePaths.BANNER_EVENT}`}
          component={BannerEventPage}
        />
        <BaseLayoutWithRoute
          title="배너 및 이벤트 등록"
          exact
          path={`${routePaths.BANNER_EVENT}/write`}
          component={BannerEventWritePage}
        />
        <BaseLayoutWithRoute
          title="배너 및 이벤트 수정"
          exact
          path={`${routePaths.BANNER_EVENT}/:id`}
          component={BannerEventEditPage}
        />

        <BaseLayoutWithRoute
          title="추천 콘텐츠 관리"
          exact
          path={routePaths.RECOMMEND_CONTENTS}
          component={RecommendContentsPage}
        />
        <BaseLayoutWithRoute
          title="추천 콘텐츠 추가"
          exact
          path={`${routePaths.RECOMMEND_CONTENTS}/write`}
          component={RecommendContentsWritePage}
        />
        <BaseLayoutWithRoute
          title="추천 콘텐츠 수정"
          exact
          path={`${routePaths.RECOMMEND_CONTENTS}/:id`}
          component={RecommendContentsEditPage}
        />
        <BaseLayoutWithRoute title="유저 리스트" exact path={routePaths.USER} component={UserListPage} />
        <BaseLayoutWithRoute title="유저 관리" exact path={`${routePaths.USER}/:id`} component={UserDetailPage} />

        <Route exact path={routePaths.LOGIN} component={LoginPage} />
        <Route exact path={routePaths.LOGOUT} component={LogoutPage} />
        <BaseLayoutWithRoute exact path="*" component={NotFoundPage} />
      </Switch>
    </Router>
  );
}

export default Routes;
