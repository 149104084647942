import { Modal } from 'antd';
import { useState } from 'react';
import { hasDuplicate } from 'utils/has-duplicate';

import DispatchTable from './DispatchTable';
import RecieveTable from './RecieveTable';

function RecommendContentsListModal({ visible, onCancel, defaultContentsList, maxContents }) {
  const [contentsList, setContentsList] = useState(defaultContentsList);
  const handleDispatch = (checkedContentsList) => {
    if (hasDuplicate(checkedContentsList, contentsList)) {
      Modal.error({
        title: '중복된 콘텐츠가 있습니다. 다시 확인해주세요.',
      });
      return;
    }

    if (contentsList.length + checkedContentsList.length > maxContents) {
      Modal.error({
        title: `${maxContents}개 이하로 등록해주세요.`,
      });

      return;
    }

    setContentsList((list) => [...list, ...checkedContentsList]);

    return true;
  };

  const deleteContents = ({ id }) => {
    setContentsList((list) => list.filter((item) => item.id !== id));
  };

  const handleCancel = () => {
    onCancel(contentsList);
  };

  return (
    <Modal title={`추천 콘텐츠`} visible={visible} onCancel={handleCancel} width={1200} footer={null}>
      <RecieveTable contentsList={contentsList} onDeleteContents={deleteContents} maxContents={maxContents} />
      <DispatchTable onDispatch={handleDispatch} />
    </Modal>
  );
}

export default RecommendContentsListModal;
