import api from 'api';
import { camelize } from 'utils/obj';

export const requestLogin = async (params) => {
  const { data, error } = await api({
    method: 'post',
    url: '/admin/login',
    data: params,
  });

  return {
    data: data ? camelize(data) : null,
    error,
    requestName: 'LOGIN',
  };
};

export const requestCheckAuth = async () => {
  const { error, ...rest } = await api({
    method: 'get',
    url: '/admin',
  });
  return {
    data: rest ? camelize(rest) : null,
    error,
    requestName: 'CHECK_AUTH',
  };
};
