import { CaretUpOutlined } from '@ant-design/icons';
import { Table, Col, Input, Row, Select, Button } from 'antd';
import { useMemo, useState } from 'react';
import styled from 'styled-components';
import useSWR from 'swr';
import { camelize } from 'utils/obj';

import { columns } from './RecommendForm';

const DEFAULT_PAGE_SIZE = 30;

function DispatchTable({ onDispatch }) {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedContentsList, setSelectedContentsList] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [category, setCategory] = useState('');
  const [page, setPage] = useState(0);
  const [pageSize] = useState(DEFAULT_PAGE_SIZE);
  const offset = useMemo(() => (page === 0 ? 0 : page * pageSize + 1), [page, pageSize]);
  const shouldFetch = useMemo(() => !!category, [category]);
  const { data } = useSWR(
    () => shouldFetch && `/contents?category=${category}&offset=${offset}&limit=${pageSize}&keyword=${searchValue}`
  );

  const contentsList = useMemo(
    () => (data?.list ? camelize(data.list).map((item) => ({ ...item, key: item.id })) : []),
    [data]
  );

  const handleCategoryChange = (value) => {
    setCategory(value);
  };
  const handleSearch = (value) => {
    setSearchValue(value);
  };

  const paginationConfig = {
    total: data?.total,
    showSizeChanger: false,
    pageSize: DEFAULT_PAGE_SIZE,
    current: page + 1,
  };

  const handleTableChange = (pagination, filters, sorter, extra) => {
    if (extra.action === 'paginate') {
      setPage(pagination.current - 1);
    }
  };

  const handleRowSelectChange = (rowKeys, rows) => {
    setSelectedRowKeys(rowKeys);
    setSelectedContentsList(rows);
  };

  const rowSelection = {
    preserveSelectedRowKeys: true,
    selectedRowKeys,
    onChange: handleRowSelectChange,
  };

  return (
    <>
      <div style={{ textAlign: 'center', padding: '12px 0' }}>
        <Button
          disabled={selectedRowKeys.length === 0}
          type="primary"
          shape="circle"
          icon={<CaretUpOutlined />}
          onClick={() => {
            if (onDispatch(selectedContentsList)) {
              setSelectedRowKeys([]);
            }
          }}
        />
      </div>
      <Row gutter={16} align="bottom" style={{ paddingBottom: 12 }}>
        <Col span={5}>
          <Select
            style={{ width: '100%' }}
            placeholder="카테고리를 선택해주세요"
            onChange={handleCategoryChange}
            options={[
              { label: '영상', value: '영상' },
              { label: '퀴즈', value: '퀴즈' },
            ]}
          />
        </Col>
        <Col span={12}>
          <Input.Search style={{ width: '100%' }} placeholder="검색어를 입력해주세요" onSearch={handleSearch} />
        </Col>
        <Col span={7} style={{ textAlign: 'right', paddingRight: 15 }}>
          {data && <span style={{ fontWeight: 500 }}>검색 결과: {data.total}</span>}
        </Col>
      </Row>
      <StyledTable
        onChange={handleTableChange}
        loading={shouldFetch && !data}
        dataSource={contentsList.map((contents) => ({
          key: contents.id,
          ...contents,
          category: contents.category4Name || contents.category2Name,
        }))}
        columns={columns}
        rowKey="id"
        rowSelection={rowSelection}
        pagination={paginationConfig}
        scroll={{ y: 300, x: 1500 }}
      />
    </>
  );
}

const StyledTable = styled(Table)`
  .ant-table-tbody > tr > td.ant-table-cell {
    padding: 12px;
  }
`;

export default DispatchTable;
