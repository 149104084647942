import { Modal } from 'antd';
import { requestEditContents } from 'api';
import ContentsForm from 'components/contents/ContentsForm';
import useCategory from 'hooks/useCategory';
import * as R from 'ramda';
import { useMemo } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import useSWR from 'swr';

function ContentsDetailPage() {
  const params = useParams();
  const location = useLocation();
  const history = useHistory();

  const isVideoPage = location.pathname.includes('video');

  const { data, mutate } = useSWR(`/contents/${params?.id}`);
  const idNameToValueLabelCategories = useCategory(isVideoPage);

  const categoriesToArray = useMemo(() => {
    return (
      data &&
      Object.entries(data)
        .map(([key, value]) => {
          // IE 안된다하면 match로 바꿀필요있음
          if (key.startsWith('category') && key.endsWith('_id')) {
            return value;
          } else {
            return false;
          }
        })
        .filter(Boolean)
    );
  }, [data]);

  const editContents = (values) => {
    (async () => {
      const { data, error } = await requestEditContents(values);
      if (error) {
        console.error(error.response);
        Modal.error({
          title: '수정에 실패하였습니다.',
        });
        return;
      }

      if (data) {
        mutate();
        const url = isVideoPage ? '/contents/video' : '/contents/quiz';
        history.push(url);
      }
    })();
  };

  const handleFinish = (values) => {
    console.log(values);
    const arrangeCategories = {
      category1_id: values?.category[0] || null,
      category2_id: values?.category[1] || null,
      category3_id: values?.category[2] || null,
      category4_id: values?.category[3] || null,
    };
    const reformedValues = R.pipe(
      R.omit(['category']),
      R.evolve({
        image_thumbnail: (value) => {
          console.log(value);
          if (Array.isArray(value)) {
            return value[0].url;
          } else {
            return value.file.response.url;
          }
        },
      })
    )(values);

    editContents({
      id: params?.id,
      ...reformedValues,
      ...arrangeCategories,
      ...(isVideoPage && { num_of_question: 0 }),
      ...(!isVideoPage && { running_time: 0 }),
    });
  };

  return (
    <>
      {data && (
        <ContentsForm
          categoryList={idNameToValueLabelCategories}
          initialValues={{
            ...data,
            image_thumbnail: [
              {
                name: data.image_thumbnail,
                url: data.image_thumbnail,
                thumbUrl: data.image_thumbnail,
                uid: '-1',
                status: 'done',
              },
            ],
            category: categoriesToArray,
          }}
          onFinish={handleFinish}
          isVideoPage={isVideoPage}
          okText="수정하기"
        />
      )}
    </>
  );
}

export default ContentsDetailPage;
