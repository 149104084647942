import api from 'api';
import * as R from 'ramda';
import { camelize } from 'utils/obj';

export const requestContentsCreate = async (params) => {
  const { data, error } = await api({
    method: 'post',
    url: '/contents',
    data: { category3_id: null, category4_id: null, ...params },
  });

  return {
    data: data ? camelize(data) : null,
    error,
    requestName: 'CONTENTS_CREATE',
  };
};

export const requestEditContents = async (params) => {
  const { data, error } = await api({
    method: 'patch',
    url: `/contents/${params?.id}`,
    data: { category3_id: null, category4_id: null, ...R.omit(['id'], params) },
  });

  return {
    data,
    error,
    requestName: 'CONTENTS_EDIT',
  };
};
export const requestEditMultipleContents = async (params) => {
  const { data, error } = await api({
    method: 'patch',
    url: '/contents',
    data: { ...params },
  });

  return {
    data,
    error,
    requestName: 'CONTENTS_EDIT',
  };
};
export const requestDeleteContents = async (params) => {
  const { data, error } = await api({
    method: 'delete',
    url: `/contents/${params?.id}`,
  });

  return {
    data,
    error,
    requestName: 'CONTENTS_DELETE',
  };
};
