export const routePaths = {
  HOME: '/',
  CONTENTS: '/contents',
  CONTENTS_VIDEO: '/contents/video',
  CONTENTS_VIDEO_CREATE: '/contents/video/create',
  CONTENTS_QUIZ: '/contents/quiz',
  CONTENTS_QUIZ_CREATE: '/contents/quiz/create',
  CATEGORY: '/category',
  BANNER_EVENT: '/banner-event',
  BOARD: '/board',
  MONTHLY_CONTENTS: '/monthly-contents',
  RECOMMEND_CONTENTS: '/recommend-contents',
  USER: '/user',
  LOGIN: '/login',
  LOGOUT: '/logout',
};
