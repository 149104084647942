import { UploadOutlined } from '@ant-design/icons';
import { Button, Cascader, Col, Form, Input, InputNumber, Rate, Row, Select, Upload } from 'antd';
import { uploadRequest } from 'api';
import { CONTENTS_INPUTS } from 'constants/inputs';
import styled from 'styled-components';

function ContentsForm({ categoryList, isVideoPage, onFinish, initialValues, okText }) {
  const [form] = Form.useForm();
  return (
    <Box>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={initialValues}
        scrollToFirstError
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item
              label={CONTENTS_INPUTS.CODE.label}
              name={CONTENTS_INPUTS.CODE.name}
              rules={CONTENTS_INPUTS.CODE.rules}
            >
              <Input type={CONTENTS_INPUTS.CODE.type} placeholder={CONTENTS_INPUTS.CODE.placeholder} />
            </Form.Item>
          </Col>
          <Col span={18}>
            <Form.Item
              label={CONTENTS_INPUTS.CATEGORY.label}
              name={CONTENTS_INPUTS.CATEGORY.name}
              rules={CONTENTS_INPUTS.CATEGORY.rules}
            >
              <Cascader placeholder={CONTENTS_INPUTS.CATEGORY.placeholder} options={categoryList} />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          label={CONTENTS_INPUTS.TITLE.label}
          name={CONTENTS_INPUTS.TITLE.name}
          rules={CONTENTS_INPUTS.TITLE.rules}
        >
          <Input type={CONTENTS_INPUTS.TITLE.type} placeholder={CONTENTS_INPUTS.TITLE.placeholder} />
        </Form.Item>
        <Form.Item
          label={CONTENTS_INPUTS.ACTIVITY_TIME.label}
          name={CONTENTS_INPUTS.ACTIVITY_TIME.name}
          rules={CONTENTS_INPUTS.ACTIVITY_TIME.rules}
        >
          <InputNumber
            style={{ width: '100%' }}
            type={CONTENTS_INPUTS.ACTIVITY_TIME.type}
            placeholder={CONTENTS_INPUTS.ACTIVITY_TIME.placeholder}
          />
        </Form.Item>
        <Row gutter={16}>
          <Col span={12}>
            <FullHeightFormItem
              label={CONTENTS_INPUTS.CONTENT.label}
              name={CONTENTS_INPUTS.CONTENT.name}
              rules={CONTENTS_INPUTS.CONTENT.rules}
            >
              <Input.TextArea placeholder={CONTENTS_INPUTS.CONTENT.placeholder} />
            </FullHeightFormItem>
          </Col>
          <Col span={12}>
            <Form.Item
              label={CONTENTS_INPUTS.URL.label}
              name={CONTENTS_INPUTS.URL.name}
              rules={CONTENTS_INPUTS.URL.rules}
            >
              <Input type={CONTENTS_INPUTS.URL.type} placeholder={CONTENTS_INPUTS.URL.placeholder} />
            </Form.Item>
            {isVideoPage ? (
              <Form.Item
                label={CONTENTS_INPUTS.RUNNING_TIME.label}
                name={CONTENTS_INPUTS.RUNNING_TIME.name}
                rules={CONTENTS_INPUTS.RUNNING_TIME.rules}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  type={CONTENTS_INPUTS.RUNNING_TIME.type}
                  placeholder={CONTENTS_INPUTS.RUNNING_TIME.placeholder}
                />
              </Form.Item>
            ) : (
              <Form.Item
                label={CONTENTS_INPUTS.NUM_OF_QUESTION.label}
                name={CONTENTS_INPUTS.NUM_OF_QUESTION.name}
                rules={CONTENTS_INPUTS.NUM_OF_QUESTION.rules}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  type={CONTENTS_INPUTS.NUM_OF_QUESTION.type}
                  placeholder={CONTENTS_INPUTS.NUM_OF_QUESTION.placeholder}
                />
              </Form.Item>
            )}
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label={CONTENTS_INPUTS.DIFFICULTY.label}
              name={CONTENTS_INPUTS.DIFFICULTY.name}
              rules={CONTENTS_INPUTS.DIFFICULTY.rules}
            >
              <Rate count={3} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={CONTENTS_INPUTS.IMAGE_THUMBNAIL.label}
              name={CONTENTS_INPUTS.IMAGE_THUMBNAIL.name}
              rules={CONTENTS_INPUTS.IMAGE_THUMBNAIL.rules}
              valuePropName={initialValues.image_thumbnail.length > 0 ? 'fileLIst' : null}
            >
              <Upload
                action={uploadRequest}
                headers={{
                  authorization: `Bearer ${(window.localStorage.getItem('__DOLBOM_ADMIN_TOKEN__') || null).replace(
                    /"/g,
                    ''
                  )}`,
                }}
                maxCount={1}
                accept="image/*"
                listType="picture"
                defaultFileList={initialValues?.image_thumbnail.length > 0 ? initialValues.image_thumbnail : []}
              >
                <Button icon={<UploadOutlined />}>
                  {initialValues?.image_thumbnail.length > 0 ? '수정하기' : '업로드'}
                </Button>
              </Upload>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label={CONTENTS_INPUTS.SELL_MATERIAL.label}
          name={CONTENTS_INPUTS.SELL_MATERIAL.name}
          rules={CONTENTS_INPUTS.SELL_MATERIAL.rules}
        >
          <Input type={CONTENTS_INPUTS.SELL_MATERIAL.type} placeholder={CONTENTS_INPUTS.SELL_MATERIAL.placeholder} />
        </Form.Item>
        <Form.Item
          label={CONTENTS_INPUTS.SELL_MATERIAL_URL.label}
          name={CONTENTS_INPUTS.SELL_MATERIAL_URL.name}
          rules={CONTENTS_INPUTS.SELL_MATERIAL_URL.rules}
        >
          <Input
            type={CONTENTS_INPUTS.SELL_MATERIAL_URL.type}
            placeholder={CONTENTS_INPUTS.SELL_MATERIAL_URL.placeholder}
          />
        </Form.Item>
        <Form.Item
          label={CONTENTS_INPUTS.SELF_MATERIAL.label}
          name={CONTENTS_INPUTS.SELF_MATERIAL.name}
          rules={CONTENTS_INPUTS.SELF_MATERIAL.rules}
        >
          <Input type={CONTENTS_INPUTS.SELF_MATERIAL.type} placeholder={CONTENTS_INPUTS.SELF_MATERIAL.placeholder} />
        </Form.Item>
        <Form.Item
          label={CONTENTS_INPUTS.ACCESS_LEVEL.label}
          name={CONTENTS_INPUTS.ACCESS_LEVEL.name}
          rules={CONTENTS_INPUTS.ACCESS_LEVEL.rules}
        >
          <Select
            options={CONTENTS_INPUTS.ACCESS_LEVEL.options}
            placeholder={CONTENTS_INPUTS.ACCESS_LEVEL.placeholder}
          />
        </Form.Item>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label={CONTENTS_INPUTS.REFERENCE_TYPE.label}
              name={CONTENTS_INPUTS.REFERENCE_TYPE.name}
              rules={CONTENTS_INPUTS.REFERENCE_TYPE.rules}
            >
              <Select
                allowClear
                options={CONTENTS_INPUTS.REFERENCE_TYPE.options}
                placeholder={CONTENTS_INPUTS.REFERENCE_TYPE.placeholder}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={CONTENTS_INPUTS.REFERENCE.label}
              name={CONTENTS_INPUTS.REFERENCE.name}
              rules={CONTENTS_INPUTS.REFERENCE.rules}
            >
              <Input type={CONTENTS_INPUTS.REFERENCE.type} placeholder={CONTENTS_INPUTS.REFERENCE.placeholder} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item
              label={CONTENTS_INPUTS.ACTIVITY_DOC.label}
              name={CONTENTS_INPUTS.ACTIVITY_DOC.name}
              rules={CONTENTS_INPUTS.ACTIVITY_DOC.rules}
            >
              <Input type={CONTENTS_INPUTS.ACTIVITY_DOC.type} placeholder={CONTENTS_INPUTS.ACTIVITY_DOC.placeholder} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={CONTENTS_INPUTS.GUIDE_DOC.label}
              name={CONTENTS_INPUTS.GUIDE_DOC.name}
              rules={CONTENTS_INPUTS.GUIDE_DOC.rules}
            >
              <Input type={CONTENTS_INPUTS.GUIDE_DOC.type} placeholder={CONTENTS_INPUTS.GUIDE_DOC.placeholder} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={CONTENTS_INPUTS.DESIGN_DOC.label}
              name={CONTENTS_INPUTS.DESIGN_DOC.name}
              rules={CONTENTS_INPUTS.DESIGN_DOC.rules}
            >
              <Input type={CONTENTS_INPUTS.DESIGN_DOC.type} placeholder={CONTENTS_INPUTS.DESIGN_DOC.placeholder} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={CONTENTS_INPUTS.QUIZ_DOC.label}
              name={CONTENTS_INPUTS.QUIZ_DOC.name}
              rules={CONTENTS_INPUTS.QUIZ_DOC.rules}
            >
              <Input type={CONTENTS_INPUTS.QUIZ_DOC.type} placeholder={CONTENTS_INPUTS.QUIZ_DOC.placeholder} />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ paddingTop: 32 }} gutter={16} justify="center">
          <Col>
            <Button size="large" block htmlType="button">
              취소
            </Button>
          </Col>
          <Col>
            <Button size="large" block type="primary" htmlType="button" onClick={form.submit}>
              {okText}
            </Button>
          </Col>
        </Row>
      </Form>
    </Box>
  );
}

const Box = styled.div`
  background-color: #fff;
  margin: 0 auto;
  border: 1px solid #e9e9e9;
  padding: 32px;
  border-radius: 8px;
  max-width: 960px;
`;

const FullHeightFormItem = styled(Form.Item)`
  height: 100%;
  .ant-form-item-control {
    height: 100%;
    flex: 1;
    .ant-form-item-control-input {
      height: 100%;
      .ant-form-item-control-input-content {
        height: 100%;
        padding-bottom: 24px;
        textarea {
          resize: none;
          height: 100%;
        }
      }
    }
  }
`;

export default ContentsForm;
