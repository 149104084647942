import { message, Modal } from 'antd';
import { requestAddDailySchedule, requestDeleteDailySchedule } from 'api';
import { useState } from 'react';
import { hasDuplicate } from 'utils/has-duplicate';

import DispatchTable from './DispatchTable';
import RecieveTable from './RecieveTable';

function DailyContentsListModal({ visible, onCancel, maxContents, selectedDayContentsList, selectedDay }) {
  const [contentsList, setContentsList] = useState(selectedDayContentsList);
  const handleDispatch = (contentsListFromServer, cb) => {
    if (hasDuplicate(contentsListFromServer, contentsList)) {
      Modal.error({
        title: '중복된 콘텐츠가 있습니다. 다시 확인해주세요.',
      });
      return;
    }
    if (contentsList.length + contentsListFromServer.length > maxContents) {
      Modal.error({
        title: `${maxContents}개 이하로 등록해주세요.`,
      });

      return;
    }

    setContentsList((prevContentsList) => prevContentsList.concat(contentsListFromServer));

    contentsListFromServer.forEach(({ id }) => {
      (async () => {
        const { data } = await requestAddDailySchedule({
          contents_id: id,
          entire_date: selectedDay,
        });

        if (data) {
          // 콘텐츠 리스트에 일치한 id를 찾아서 해당 객체의 스케쥴 아이디를 업데이트 해준다.
          setContentsList((prevContentsList) => {
            const index = prevContentsList.findIndex((contents) => contents.id === data.contentsId);
            const updatedContents = {
              ...prevContentsList[index],
              dailyScheduleId: data.id,
            };

            // make final new array of objects by combining updated object.
            const updatedContentsList = [
              ...prevContentsList.slice(0, index),
              updatedContents,
              ...prevContentsList.slice(index + 1),
            ];

            return updatedContentsList;
          });

          message.success('등록이 완료되었습니다.');
        }
      })();
    });
    cb && cb();
  };
  const deleteContents = ({ id }) => {
    (async () => {
      const { error } = await requestDeleteDailySchedule({ id });

      if (error) {
        Modal.error({
          title: '콘텐츠 삭제에 실패하였습니다.',
        });
        return;
      }

      message.success('콘텐츠가 삭제되었습니다.');
      onCancel();
    })();
  };

  return (
    <Modal title={`${selectedDay} 콘텐츠`} visible={visible} onCancel={onCancel} width={1200} footer={null}>
      <RecieveTable
        contentsList={contentsList}
        onDeleteContents={deleteContents}
        isDaily={true}
        maxContents={maxContents}
      />
      <DispatchTable onDispatch={handleDispatch} />
    </Modal>
  );
}

export default DailyContentsListModal;
