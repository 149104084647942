import { Rate } from 'antd';
import { formatTimeStr } from 'antd/lib/statistic/utils';
import Thumbnail from 'components/common/Thumbnail';

export const columns = [
  {
    title: '코드',
    dataIndex: 'code',
    key: 'code',
    width: 120,
    fixed: 'left',
    align: 'center',
  },
  {
    title: '썸네일',
    dataIndex: 'imageThumbnail',
    key: 'imageThumbnail',
    render: (imageThumbnail) => <Thumbnail src={imageThumbnail} />,
    fixed: 'left',
    width: 80,
    align: 'center',
  },

  {
    title: '카테고리',
    width: 120,
    align: 'center',
    render: (_, record) => record?.category4Name ?? record?.category2Name,
  },
  {
    title: '제목',
    dataIndex: 'title',
    key: 'title',
    width: 300,
  },
  {
    title: '영상 길이',
    dataIndex: 'runningTime',
    key: 'runningTime',
    width: 90,
    align: 'center',
    render: (runningTime) => {
      return formatTimeStr(runningTime * 1000, 'mm:ss');
    },
  },
  {
    title: '난이도',
    dataIndex: 'difficulty',
    key: 'difficulty',
    width: 120,
    align: 'center',
    render: (difficulty) => <Rate key={difficulty} defaultValue={difficulty} count={3} disabled />,
  },
  {
    title: '좋아요',
    dataIndex: 'likeCount',
    key: 'likeCount',
    width: 80,
    align: 'center',
  },
  {
    title: '조회 수',
    dataIndex: 'viewCount',
    key: 'viewCount',
    width: 80,
    align: 'center',
  },
  {
    title: '등급',
    dataIndex: 'accessLevel',
    key: 'accessLevel',
    width: 100,
    align: 'center',
    render: (accessLevel) => (accessLevel === 1 ? '전체' : '프리미엄'),
  },
  {
    title: '준비물',
    dataIndex: 'sellMaterial',
    key: 'sellMaterial',
    align: 'center',
    width: 70,
    render: (sellMaterial) => (sellMaterial ? '🟢' : ''),
  },
  {
    title: '활동지',
    dataIndex: 'activityDoc',
    key: 'activityDoc',
    align: 'center',
    width: 70,
    render: (activityDoc) => (activityDoc ? '🟢' : ''),
  },
  {
    title: '지도서',
    dataIndex: 'guideDoc',
    key: 'guideDoc',
    align: 'center',
    width: 70,
    render: (guideDoc) => (guideDoc ? '🟢' : ''),
  },
  {
    title: '도안',
    dataIndex: 'designDoc',
    key: 'designDoc',
    align: 'center',
    width: 70,
    render: (designDoc) => (designDoc ? '🟢' : ''),
  },
  {
    title: '퀴즈',
    dataIndex: 'quizDoc',
    key: 'quizDoc',
    align: 'center',
    width: 70,
    render: (quizDoc) => (quizDoc ? '🟢' : ''),
  },
];
